import RoutesPage from "./Routes";
import { AlertProvider } from "./services/context/AlertContext";
import FormContextProvider from "./services/context/FormContextProvider";
import FormRendererContextProvider from "./services/context/FormRendererContextProvider";
import CareFlowContextProvider from "./services/context/CareFlowContextProvider";
import CssBaseline from "@mui/material/CssBaseline";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function App() {
  return (
    <AlertProvider>
      <FormContextProvider>
        <FormRendererContextProvider>
        <CareFlowContextProvider>
          <CssBaseline />
          <div className="app">
            <DndProvider backend={HTML5Backend}>
              <RoutesPage />
            </DndProvider>
          </div>
          </CareFlowContextProvider>
        </FormRendererContextProvider>
      </FormContextProvider>
    </AlertProvider>
  );
}

export default App;
