import { useState, useEffect } from "react";
import "./common.scss";
import Select from "react-select";

const DropDown = ({ data, setAnswer, placeholder, isDisabled = false }) => {
  const [selectedOption, setSelectedOption] = useState(data?.answer || null);
  function handleChange(e) {
    setSelectedOption({ value: e.target.value });
    setAnswer && setAnswer({ value: e.target.value });
  }

  useEffect(() => {
    if (data?.answer) {
      setSelectedOption(data.answer);
    }
  }, [data]);

  return (
    <div className=" w-full relative dropdown-select">
      {data?.label && <div className="form-title pt-3">{data?.label}</div>}
      <select
      required
        disabled={isDisabled}
        className="new-dropdown radio-common text-field text-input"
        value={selectedOption?.value}
        onChange={handleChange}
      >
        <option className="placeholder-text" value="" disabled selected hidden>
          {placeholder || "Please Choose..."}
        </option>

        {data &&
          data?.options &&
          data.options?.length &&
          data.options.map((option) => {
            return (
              <option value={option?.value}>
                {option?.label || option?.value || "option"}
              </option>
            );
          })}
      </select>
      {selectedOption?.badge && (
        <div className="absolute dropdown-badge">{selectedOption?.badge}</div>
      )}
    </div>
  );
};
export default DropDown;
//menuIsOpen={true}/>
