import { Box, Alert, CircularProgress, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import UploadDocuments from "./components/Modal/UploadDocuments";
import SelectAppointment from "./components/Modal/SelectAppointment";
import Complete from "./components/Modal/Complete";
import UploadNotes from "./components/Modal/UploadNotes";
import CustomButton, {
  CustomTextButton,
} from "../../components/common/CustomButton";
import { hardCodedData } from "../../hardCode/constants";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "../../components/common/TextField";
import DateInput from "../../components/common/DateInput";
import Email from "../../components/common/Email";
import { getAuthToken } from "../../utils/cookie";
import axios from "axios";
import { isValidEmail } from "../../utils/emailValidation";
import AnimatedTick from "./components/animatedTick/animatedTick";
import DropDown from "../../components/common/dropdown";

const CustomAction = () => {
  const [step, setStep] = useState(0);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [openPazReferralModal, setOpenPazReferralModal] = useState(false);

  const [referralStep, setReferralStep] = useState(0);

  const [firstName, setFirst] = useState("");
  const [lastName, setLast] = useState("");
  const [dob, setDateOfBirth] = useState(null);
  const [email, setEmail] = useState("");
  const [referral, setReferral] = useState(null);
  const [gender, setGender] = useState("");

  const [firstNameValidation, setValidationForFirstName] = useState(true);
  const [lastNameValidation, setValidationForLastName] = useState(true);
  const [dobValidation, setValidationForDob] = useState(true);
  const [referralValidation, setValidationForReferral] = useState(true);
  const [emailValidation, setValidationForEmail] = useState(true);
  const [genderValidation, setValidationForGender] = useState(true);

  const [invalidMessage, setInvalidMessage] = useState("Required Field");

  const [alertMessage, setAlertMessage] = useState("something went wrong");
  const [showAlert, setShowAlert] = useState(false);

  const user = useSelector((state) => state?.user);
  const { customActionsAllowedOrgs } = hardCodedData;
  const { DrPazReferralAllowedOrgs } = hardCodedData;

  const handleOpenDocuments = () => {
    setOpenDocuments(true);
  };
  const [openNotes, setOpenNotes] = useState(false);
  const handleOpenNotes = () => {
    setOpenNotes(true);
  };
  const handleCloseDocuments = () => {
    setOpenDocuments(false);
    setStep(0);
  };
  const handleCloseNotes = () => {
    setOpenNotes(false);
    setStep(0);
  };
  const handlePazReferral = () => {
    setInvalidMessage("Required Field");
    if (!firstName) setValidationForFirstName(false);
    if (!lastName) setValidationForLastName(false);
    if (!dob) setValidationForDob(false);
    if (!referral) setValidationForReferral(false);
    if (!gender) setValidationForGender(false);
    if (!email) setValidationForEmail(false);
    else if (email && !isValidEmail(email)) {
      setValidationForEmail(false);
      setInvalidMessage("Invalid Email");
    }
    if (firstName && lastName && dob && email && isValidEmail(email) && gender && referral) {
      setReferralStep("loader");
      handlePazReferralSubmission();
    }
  };

  async function handlePazReferralSubmission() {
    let API_URL = `${
      process.env?.REACT_APP_FOUNTAIN_HEAD_URL ||
      "https://fountainhead-sandbox.healthblox.care"
    }/api/v1/pilots/synaptrx/patients`;
    try {
      const headers = getAuthToken();
      const reqBody = {
        firstName,
        lastName,
        DOB: dateFormatter(dob),
        // referredBy: referral,
        email: [email],
        sex: gender,
      };
      const response = await axios.post(API_URL, reqBody, headers);
      // setOpenPazReferralModal(false);
      setReferralStep(1);
      setFirst("");
      setLast("");
      setDateOfBirth(null);
      setEmail("");
    } catch (error) {
      // setOpenPazReferralModal(false);
      setAlertMessage(error.response?.data?.message || "Something went wrong");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      setReferralStep(0);
      console.log(error);
    }
  }

  function setFirstName(name, data) {
    setValidationForFirstName(true);
    setFirst(data.value);
  }
  function setLastName(name, data) {
    setValidationForLastName(true);
    setLast(data.value);
  }
  function setDOB(name, data) {
    setValidationForDob(true);
    setDateOfBirth(data.value);
  }
  function dateFormatter(date) {
    const formattedDate = moment(date?.$d).format("MM/DD/YYYY");
    return formattedDate;
  }
  function setEmailId(name, data) {
    setValidationForEmail(true);
    setEmail(data.value);
  }
  function setReferredBy(data) {
    setValidationForReferral(true);
    setReferral(data.value);
  }
  function setSex(data) {
    setValidationForGender(true);
    setGender(data.value);
  }

  return (
    <Box m="20px">
      {/* <Box display="flex" justifyContent={"space-between"} p={1.5}>
        <Typography variant="h5">CUSTOM SPACE</Typography>
      </Box> */}
      {user && user?.org && user?.org?._id ? (
        <>
          <Box m="20px">
            <Typography variant="h6">Actions</Typography>
          </Box>
          {customActionsAllowedOrgs &&
          customActionsAllowedOrgs?.length &&
          customActionsAllowedOrgs.includes(user?.org?._id) ? (
            <Box m="20px">
              <CustomButton onClick={handleOpenDocuments}>
                Upload Documents
              </CustomButton>
            </Box>
          ) : (
            <></>
          )}
          {customActionsAllowedOrgs &&
          customActionsAllowedOrgs?.length &&
          customActionsAllowedOrgs.includes(user?.org?._id) ? (
            <Box m="20px">
              <CustomButton onClick={handleOpenNotes}>
                Add Appointment Notes
              </CustomButton>
            </Box>
          ) : (
            <></>
          )}
          {DrPazReferralAllowedOrgs &&
          DrPazReferralAllowedOrgs?.length &&
          DrPazReferralAllowedOrgs.includes(user?.org?._id) ? (
            <Box m="20px">
              <CustomButton onClick={() => setOpenPazReferralModal(true)}>
                Patient SignUp
              </CustomButton>
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <Modal open={openDocuments}>
        <Box sx={style}>
          {step === 0 && (
            <SelectAppointment
              next={() => {
                setStep(1);
              }}
              closeModal={handleCloseDocuments}
            />
          )}
          {step === 1 && (
            <UploadDocuments
              next={() => {
                setStep(2);
              }}
              closeModal={handleCloseDocuments}
            />
          )}
          {step === 2 && (
            <Complete
              closeModal={handleCloseDocuments}
              text="UPLOAD ANOTHER"
              next={() => {
                setStep(0);
              }}
            />
          )}
        </Box>
      </Modal>

      <Modal open={openNotes}>
        <Box sx={style}>
          {step === 0 && (
            <SelectAppointment
              next={() => {
                setStep(1);
              }}
              closeModal={handleCloseNotes}
            />
          )}
          {step === 1 && (
            <UploadNotes
              closeModal={handleCloseNotes}
              next={() => {
                setStep(2);
              }}
            />
          )}
          {step === 2 && (
            <Complete
              closeModal={handleCloseNotes}
              next={() => {
                setStep(0);
              }}
              text="ADD ANOTHER"
            />
          )}
        </Box>
      </Modal>

      <Modal open={openPazReferralModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            p: 2,
          }}
        >
          {referralStep === 0 && (
            <div className="flex flex-col">
              <div
                className="flex justify-end cursor-pointer"
                onClick={() => setOpenPazReferralModal(false)}
              >
                <CloseIcon />
              </div>
              {showAlert && (
                <div className="alert-overlay">
                  <Alert
                    className="alert-content position_static"
                    severity="error"
                    onClose={() => {
                      setShowAlert(false);
                    }}
                  >
                    {alertMessage}
                  </Alert>
                </div>
              )}
              <div>
                <div className="form-width mx-auto">
                  <div className="flex gap-3">
                    <TextField
                      data={{
                        label: "First Name",
                        answer: { value: firstName },
                        validate: { required: true },
                      }}
                      setAnswer={setFirstName}
                      formValidation={firstNameValidation}
                    />
                    <TextField
                      data={{
                        label: "Last Name",
                        answer: { value: lastName },
                        validate: { required: true },
                      }}
                      setAnswer={setLastName}
                      formValidation={lastNameValidation}
                    />
                  </div>
                  <div className="flex gap-3">
                    <DateInput
                      data={{
                        label: "DOB",
                        answer: { value: dob },
                        validate: { required: true },
                      }}
                      setAnswer={setDOB}
                      formValidation={dobValidation}
                    />
                    <Email
                      data={{
                        label: "Email",
                        answer: { value: email },
                        validate: { required: true },
                      }}
                      invalidMessage={invalidMessage}
                      setAnswer={setEmailId}
                      formValidation={emailValidation}
                    />
                  </div>
                  <div className="flex gap-3">
                    <div className="my-2 basis-1/2">
                      <div className="mb-2 mt-2">
                        Referred by
                        <span
                          className={!referralValidation ? "text-red-700" : ""}
                        >
                          *
                        </span>
                      </div>
                      <DropDown
                        data={{
                          options: [
                            { label: "DR. Paz", value: "paz" },
                            { label: "Others", value: "others" },
                          ],
                          answer: { value: referral },
                        }}
                        // className="input-style"
                        placeholder={"Referred by"}
                        setAnswer={setReferredBy}
                      />
                      {!referralValidation ? (
                        <span className="text-red-700 font-light text-xs">
                          Required Field
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="my-2 basis-1/2">
                      <div className="mb-2 mt-2">
                        Sex
                        <span
                          className={!genderValidation ? "text-red-700" : ""}
                        >
                          *
                        </span>
                      </div>
                      <DropDown
                        data={{
                          options: [
                            { label: "Male", value: "M" },
                            { label: "Female", value: "F" },
                          ],
                          answer: { value: gender },
                        }}
                        // className="input-style"
                        placeholder={"sex"}
                        setAnswer={setSex}
                      />
                      {!genderValidation ? (
                        <span className="text-red-700 font-light text-xs">
                          Required Field
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end pt-3">
                <CustomButton onClick={handlePazReferral}>Submit</CustomButton>
              </div>
            </div>
          )}
          {referralStep === 1 && (
            <>
              <Box display="flex" justifyContent={"center"}>
                <Box
                  m={"20px"}
                  display="flex"
                  flexDirection={"column"}
                  style={{ alignSelf: "center" }}
                >
                  <AnimatedTick style={{ alignSelf: "center" }} />
                  <Typography color={"green"} style={{ alignSelf: "center" }}>
                    SUBMITTED
                  </Typography>
                </Box>
              </Box>
              <div className="flex justify-between pt-3 px-3">
                <CustomTextButton onClick={() => setReferralStep(0)}>
                  Create New Referral
                </CustomTextButton>
                <CustomButton onClick={() => setOpenPazReferralModal(false)}>
                  Close
                </CustomButton>
              </div>
            </>
          )}
          {referralStep === "loader" && (
            <>
              <div className="flex justify-center py-24">
                <CircularProgress className="m-auto purple-text" />
              </div>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomAction;

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
