import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { generateUID } from "../../utils/generateUID";
import { FormBuilder } from "@formio/react";
import "bootstrap/dist/css/bootstrap.css";
import { saveForm, getFormData, updateFormData } from "../../utils/form";
import { FiCheck, FiX } from "react-icons/fi";
import { RiEditFill } from "react-icons/ri";
import { GiCheckMark } from "react-icons/gi";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  // bgcolor: 'background.paper',
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditForm = () => {
  const [formData, setFormData] = useState([]);
  const [form, setForm] = useState(null);
  const [activeFormPage, setActiveFormPage] = useState(null);
  const [formPageCounter, setFormPageCounter] = useState(0);

  const [showInput, setShowInput] = useState(false);
  const [inputFormValue, setInputFormValue] = useState("");

  const [openModal, setopenModal] = useState(false);

  let navigate = useNavigate();
  const { formId } = useParams();

  const increaseFormPage = () => {
    let newPage = {
      label: `form page ${formPageCounter + 1}`,
      id: `form-page-${generateUID()}`,
      content: {
        display: "form",
        components: [],
      },
    };
    if (formData?.length === 0) {
      newPage.active = true;
      setActiveFormPage(newPage.id);
    }
    setFormData((prev) => [...prev, newPage]);
    setFormPageCounter((prev) => prev + 1);
  };

  const deleteFormPage = () => {
    let temp = JSON.parse(JSON.stringify(formData));
    for (let i = 0; i < temp?.length; i++) {
      if (temp[i]?.id === activeFormPage) {
        temp?.splice(i, 1);
        if (i > 0) {
          setActiveFormPage(temp[i - 1].id);
          temp[i - 1].active = true;
        } else setActiveFormPage(null);
        break;
      }
    }
    setFormData(temp);
  };
  const handlePageClick = (id) => {
    let tempSchema = JSON.parse(JSON.stringify(formData));
    tempSchema.map((item) => {
      if (item.id === id) item.active = true;
      else item.active = false;
    });
    setActiveFormPage(id);
    setFormData(tempSchema);
  };
  async function handleSaveForm() {
    setopenModal(true);
  }

  async function handleCloseModal(event, reason) {
    if (reason !== "backdropClick") {
      let tempFormData = {
        label: inputFormValue || `form-${generateUID()}`,
        creator_id: "creator_id",
        creator_name: "creator_name",
        org: "org",
        org_name: "org_name",
        form_schema: {
          formData: [...formData],
        },
      };
      await saveForm(tempFormData);
      setInputFormValue("");
      navigate(-1);
    }
  }

  async function fetchFormData() {
    const res = await getFormData(formId);
    setFormData(res?.data?.form?.form_schema?.formData);
    setForm(res?.data?.form);
    setFormPageCounter(res?.data?.form?.form_schema?.formData?.length);
  }

  async function handleUpdateForm() {
    await updateFormData(formId, {
      form_schema: {
        formData: formData,
      },
    });
    navigate(-1);
  }

  async function updateFormLabel() {
    await updateFormData(formId, {
      label: inputFormValue,
      form_schema: {
        formData: formData,
      },
    });
    fetchFormData();
    setInputFormValue("");
    setShowInput(false);
  }

  useEffect(() => {
    if (formId && formId !== "undefined") {
      fetchFormData();
    } else {
    }
  }, []);
  return (
    <div className=" container form-journey mx-auto p-20">
      {showInput ? (
        <div className="flex mb-3 p-4 justify-center">
          <input
            type="text"
            value={inputFormValue}
            onChange={(e) => setInputFormValue(e.target.value)}
            placeholder="Form Name"
            className="px-2 py-1 h-fit rounded border-2 mustard_border inline-block"
          ></input>
          <FiCheck
            size={32}
            className="my-auto ml-2 rounded purple_bg_dark p-2 cursor-pointer text-white cursor-pointer"
            onClick={updateFormLabel}
          />
          <FiX
            size={32}
            className="my-auto ml-2 rounded bg-red-500 p-2 cursor-pointer text-white cursor-pointer"
            onClick={() => setShowInput(false)}
          />
        </div>
      ) : (
        <>
          {form?.label ? (
            <div className="text-3xl text-center p-4 flex justify-center mb-3 font-semibold">
              {form?.label}
              <RiEditFill
                size={26}
                className="my-auto ml-2 cursor-pointer text-green-700"
                onClick={() => setShowInput(true)}
              />
            </div>
          ) : (
            <div className="text-3xl text-center p-4 flex justify-center mb-3 font-semibold">
              Form
            </div>
          )}
        </>
      )}
      <div className="flex justify-between">
        {formId && formId !== "undefined" ? (
          <div className="flex">
            <div
              onClick={handleUpdateForm}
              className="bg-green-200 px-2 py-2 rounded mx-3 cursor-pointer"
            >
              Save and Return
            </div>
            <div
              onClick={handleSaveForm}
              className="bg-green-200 px-2 py-2 rounded mx-3 cursor-pointer"
            >
              Save this as a new Form
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div
              onClick={handleSaveForm}
              className="bg-green-200 px-2 py-2 rounded mx-3 cursor-pointer"
            >
              Save and Return
            </div>
          </>
        )}
        <div
          className="mustard_bgpx-3 py-2 rounded mx-3 cursor-pointer text-white"
          onClick={() => navigate(-1)}
        >
          Return to Dashboard
        </div>
      </div>
      <div className="flex py-3">
        {formData && formData?.length ? (
          formData.map((item, idx) => {
            return (
              <div
                className={
                  item.active
                    ? "rounded bg-blue-400 px-3 py-2 mx-2 cursor-pointer"
                    : "rounded bg-red-400 px-3 py-2 mx-2"
                }
                onClick={() => handlePageClick(item.id)}
              >
                {item.label}
              </div>
            );
          })
        ) : (
          <></>
        )}
        <div
          className="rounded bg-slate-400 px-3 py-2 mx-2 cursor-pointer"
          onClick={increaseFormPage}
        >
          create Form Page
        </div>
      </div>
      {activeFormPage && (
        <div className="flex justify-end">
          <div
            className="rounded bg-indigo-400 text-white px-3 py-2 mx-2 cursor-pointer"
            onClick={deleteFormPage}
          >
            Delete Form Page
          </div>
        </div>
      )}

      {formData && formData?.length ? (
        formData.map((item, idx) => {
          return (
            <div>
              {item && item.active && (
                <div>
                  <FormBuilder
                    form={item.content}
                    options={{
                      builder: {
                        basic: {
                          components: {
                            button: false,
                          },
                        },
                        advanced: {
                          components: {
                            url: false,
                            phonenumber: false,
                            tags: false,
                            address: false,
                            day: false,
                            time: false,
                            currency: false,
                            survey: false,
                            signature: false,
                          },
                        },
                        layout: {
                          components: {
                            htmlelement: false,
                            columns: false,
                            fieldset: false,
                            panel: false,
                            table: false,
                            tabs: false,
                            well: false,
                          },
                        },
                        premium: {
                          components: {
                            recaptcha: false,
                            form: false,
                            unknown: false,
                          },
                        },
                      },
                    }}
                  />
                </div>
              )}
            </div>
          );
        })
      ) : (
        <></>
      )}
      {openModal ? (
        <>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="rounded bg-zinc-300">
              <div className="font-semibold text-xl py-2">Form Name:</div>
              <input
                type="text"
                value={inputFormValue}
                onChange={(e) => setInputFormValue(e.target.value)}
                placeholder="Form Name"
                className="px-2 py-1 h-fit rounded border-2 mustard_border inline-block"
              ></input>
              {inputFormValue && inputFormValue !== "" ? (
                <div
                  className="flex justify-end my-2"
                  onClick={handleCloseModal}
                >
                  <div className="flex rounded mustard_bgpy-2 cursor-pointer text-white px-3">
                    <div className=""> Confirm</div>
                    <GiCheckMark size={22} className="my-auto ml-1 p-1 " />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Box>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default EditForm;
