import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { FiCheck, FiX } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import "./FormJourney.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import {
  getAllJourney,
  deleteJourney,
  saveJourney,
  updateJourneyStatus,
} from "../../utils/form";

import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiDraftFill } from "react-icons/ri";
import { HiClock } from "react-icons/hi2";
import { FiMinusCircle } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import TableLayout from "../../components/common/TableLayout";
import Container from "@mui/material/Container";

import { Box, Typography } from "@mui/material";
import CustomButton from "../../components/common/CustomButton";
import { Add } from "@mui/icons-material";

const FormJourney = () => {
  const [showCreateJourney, setShowCreateJourney] = useState(false);
  const [newJourneyValue, setNewJourneyValue] = useState("");
  const [journeyList, setJourneyList] = useState(null);

  const [openAllStatus, setOpenAllStatus] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const createNewJourney = () => {
    if (!newJourneyValue) return;
    let newJourney = {
      label: newJourneyValue,
      journey_schema: {
        default: null,
        label: newJourneyValue,
      },
    };
    setNewJourneyValue("");
    setShowCreateJourney(false);
    saveAllJourney(newJourney);
  };
  const saveAllJourney = async (newJourney) => {
    setIsLoading(true);
    const response = await saveJourney(newJourney);
    let tempJourneyList = [...journeyList];
    tempJourneyList.push(response?.data?.journey);
    setJourneyList(tempJourneyList);
    setIsLoading(false);
  };

  const getAllJourneyData = async () => {
    setIsLoading(true);
    const response = await getAllJourney();
    setJourneyList(response?.data?.journeys);
    setIsLoading(false);
  };
  const handleDeleteJourney = async (id) => {
    setIsLoading(true);
    await deleteJourney(id);
    let tempJourneyList = [...journeyList];
    setJourneyList(tempJourneyList?.filter((journey) => journey._id !== id));
    setIsLoading(false);
  };

  const handleStatusUpdate = async (status, id) => {
    setIsLoading(true);
    await updateJourneyStatus(id, status);
    await getAllJourneyData();
    setOpenAllStatus(null);
    setIsLoading(false);
  };

  const columns = [
    { id: "label", label: "Journey Name", align: "center" },
    {
      id: "creator_name",
      label: "Creator Name",
      minWidth: 100,
      align: "center",
    },
    {
      id: "created_at",
      label: "Created at",
      align: "center",
      format: (value) => value?.split("T")[0].split("-").reverse().join("-"),
    },
    { id: "org_name", label: "Organisation", align: "center" },
    {
      id: "status",
      label: "Status",
      align: "center",
      format: (value, row) => statusFormatter(value, row),
    },
    {
      id: "actions",
      label: "Actions",
      align: "center",
      format: (value, row) => actionFormatter(value, row),
    },
  ];

  const filters = [
    { label: "All", id: "ALL", filterKey: "status", ICON: "" },
    { label: "New", id: "NEW", filterKey: "status", ICON: "MdFiberNew" },
    {
      label: "Published",
      id: "PUBLISHED",
      filterKey: "status",
      ICON: "IoIosCheckmarkCircle",
    },
    { label: "Draft", id: "DRAFT", filterKey: "status", ICON: "RiDraftFill" },
    { label: "Archived", id: "ARCHIVED", filterKey: "status", ICON: "HiClock" },
  ];

  const statusFormatter = (status, row) => {
    const statusConfig = {
      NEW: {
        icon: <HiClock className="my-auto" color={"gray"} />,
        text: "New",
      },
      ARCHIVED: {
        icon: <FiMinusCircle className="my-auto" color={"red"} />,
        text: "Archived",
      },
      DRAFT: {
        icon: <RiDraftFill className="my-auto" color={"gray"} />,
        text: "Draft",
      },
      PUBLISHED: {
        icon: <IoIosCheckmarkCircle className="my-auto" color={"green"} />,
        text: "Published",
      },
    };
    const config = statusConfig[status];
    if (config) {
      return (
        <>
          <div className="flex justify-center">
            <div className="rounded-2xl border-gray-700 border flex w-fit px-2">
              {config.icon}
              <span className="pl-1">{config.text}</span>
            </div>
            <div className="flex px-1 w-fit px-2 my-auto">
              <MdModeEdit
                className="cursor-pointer"
                onClick={() => setOpenAllStatus(row?._id)}
              />
            </div>
          </div>
          {openAllStatus && openAllStatus === row?._id ? (
            <>
              <div className="flex justify-center pt-2 gap-2">
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("NEW", row._id)}
                >
                  New
                </div>
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("ARCHIVED", row._id)}
                >
                  Archived
                </div>
              </div>
              <div className="flex justify-center pt-1 gap-2">
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("DRAFT", row._id)}
                >
                  Draft
                </div>
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("PUBLISHED", row._id)}
                >
                  Published
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <div className="rounded-2xl border-gray-700 border flex px-1 w-fit">
          <span>{status}</span>
        </div>
      );
    }
  };

  const actionFormatter = (value, row) => {
    return (
      <div className="flex justify-center">
        <MdDelete
          size={32}
          className="cursor-pointer p-1"
          onClick={() => handleDeleteJourney(row?._id)}
        />
        <Link
          key={row?.label}
          className="no-underline px-2 mustard_text"
          to={`/edit-journey/${row?._id}`}
        >
          <IoIosArrowRoundForward size={32} className="cursor-pointer" />
        </Link>
        <a
          className="no-underline px-2 mustard_text"
          href={`${
            process.env?.REACT_APP_JOURNEY_RENDERER_URL ||
            "journey-sandbox.healthblox.care"
          }/${row?._id}`}
          target="_blank"
        >
          <FaEye size={32} className="cursor-pointer" />
        </a>
      </div>
    );
  };

  useEffect(() => {
    getAllJourneyData();
  }, []);

  return (
      <Box m={2} pb={4}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h4">User Journeys</Typography>
          {showCreateJourney ? (
            <div className="flex">
              <input
                type="text"
                value={newJourneyValue}
                onChange={(e) => setNewJourneyValue(e.target.value)}
                placeholder="Journey Name"
                className="px-2 border mustard_border rounded"
              ></input>
              <FiCheck
                size={40}
                className="my-auto ml-2 rounded purple_bg_dark p-2 cursor-pointer text-white"
                onClick={createNewJourney}
              />
              <FiX
                size={40}
                className="my-auto ml-2 rounded bg-red-500 p-2 cursor-pointer text-white"
                onClick={() => setShowCreateJourney(false)}
              />
            </div>
          ) : (
            <CustomButton onClick={() => setShowCreateJourney(true)}>
              <Add /> {"Create New Journey"}
            </CustomButton>
          )}
        </Box>
        {isLoading ? (
          <div className="flex justify-center py-24">
            <CircularProgress className="m-auto purple-text" />
          </div>
        ) : (
          <TableLayout columns={columns} rows={journeyList} filters={filters} />
        )}
      </Box>
  );
};
export default FormJourney;
