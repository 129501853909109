import { LoginLayout } from "../../layouts/LoginLayout";
import { Box, CircularProgress, Link, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { readCookie, startSession } from "../../utils/sessionutils";
import { postRequest } from "../../utils/request";
import MfaLogin from "./mfaLogin";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAlert } from "../../services/context/AlertContext";
import CustomButton from "../../components/common/CustomButton";
import CustomInput, {
  CustomFormControlLabel,
} from "../../components/common/CustomInput";
const Login = () => {
  const login = useSelector((state) => state.islogin);
  const location = useLocation();
  const { handleAlert } = useAlert();
  useEffect(() => {
    readCookie();
    if (location.hash === "#verification=success") {
      handleAlert("Verification Successful... Login to continue", "success");
    }
    if (login && location.state?.from.pathname)
      navigate(location.state?.from.pathname);
  }, [login]);

  const [remember, setRemember] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mfa, setMfa] = useState(false);
  const [type, setType] = useState(null);
  const [method, setMethod] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      email: email,
      password: password,
      remember: remember,
    };
    try {
      const response = await postRequest("user/login", data, {
        withCredentials: true,
      });
      console.log(response);
      if (response.data.mfa) {
        setMfa(true);
        setMethod(response.data.mfa);
        if (response.data.mfa.length < 2) {
          setType(response.data.mfa[0]);
        }
        console.log(response.data.mfa);
      } else {
        await startSession(response.data.token);
        if (location.state?.from) {
          navigate(location.state.from);
        } else {
          navigate("/");
        }
      }
    } catch (e) {
      console.log(e.response?.data);
      // alert(e.response?.data?.message ?? "Something went wrong");
      handleAlert(e.response?.data?.message ?? "Something went wrong", "error");
    }
    setLoading(false);
  };
  return (
    <LoginLayout
      heading={mfa ? type ?? "Choose MFA method" : "Sign in"}
      social={!mfa}
    >
      {mfa ? (
        type ? (
          <Stack>
            <MfaLogin
              remember={remember}
              email={email}
              type={method.length > 1 ? type : method[0]}
            />
            <CustomButton onClick={() => setType(null)}>BACK</CustomButton>
          </Stack>
        ) : (
          <Stack
            spacing={1}
            display={"flex"}
            flexDirection={"column"}
            // noValidate
            sx={{ mt: 1 }}
          >
            {method.map((mfaMethod, index) => (
              <CustomButton key={index} onClick={() => setType(mfaMethod)}>
                {mfaMethod}
              </CustomButton>
            ))}
          </Stack>
        )
      ) : (
        <Stack spacing={1}>
          <Stack>
            <Box
              component="form"
              onSubmit={handleSubmit}
              // noValidate
            >
              <CustomInput
                labelText={"Email address"}
                margin="normal"
                required
                fullWidth
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <CustomInput
                labelText={"Password"}
                password={true}
                margin="normal"
                required
                fullWidth
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                name="password"
                id="password"
                autoComplete="current-password"
              />
              <CustomFormControlLabel
                labelText="Remember me"
                id="rememberme"
                value="remember"
                checked={remember}
                onChange={() => {
                  setRemember(!remember);
                }}
              />
              <CustomButton
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
                type="submit"
                fullWidth
              >
                {loading ? <CircularProgress size={25} /> : "Sign In"}
              </CustomButton>
            </Box>
          </Stack>

          <Stack>
            <Link href="/forgot_password" variant="body2" color={"#CF7447"}>
              Forgot password?
            </Link>
          </Stack>
        </Stack>
      )}
    </LoginLayout>
  );
};
export default Login;
