import Store from "../services/redux/store";
import { setLogin, setUser } from "../services/redux/action";
import Cookies from "js-cookie";
import { getRequest, postRequest } from "./request";

export const isTokenValid = async () => {
  try {
    const response = await getRequest("user/me");
    Store.dispatch(setUser(response.data));
    if (!response.data.user.onboarded) {
      window.location.href = "/signup";
    }
    return true;
  } catch (e) {
    clearCookie();
    return false;
  }
};

export const readCookie = () => {
  if (Cookies.get(process.env.REACT_APP_TOKEN_NAME)) {
    startSession();
  } else {
    clearCookie();
  }
};

export const startSession = async () => {
  try {
    let token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
    if (token) {
      const response = await getRequest("user/me");
      Store.dispatch(setUser(response.data));
      Store.dispatch(setLogin(true));
      window.localStorage.setItem("isLoggedIn", true);
    }
    // if (!response.data.user.onboarded) {
    //   window.location.href = "/signup";
    // and not join_org url
    // }
  } catch (e) {
    console.log(e);
  }
};

export const endSession = async () => {
  try {
    if (Cookies.get(process.env.REACT_APP_TOKEN_NAME)) {
      await postRequest(
        "user/logout",
        {},
        {
          withCredentials: true,
        }
      );
    }
    window.localStorage.removeItem("isLoggedIn");
    Store.dispatch(setLogin(false));
    Store.dispatch(setUser(null));
  } catch (e) {
    console.log(e);
  }
};

export const clearCookie = async () => {
  try {
    if (Cookies.get(process.env.REACT_APP_TOKEN_NAME)) {
      await postRequest(
        "user/clear-cookie",
        {},
        {
          withCredentials: true,
        }
      );
    }
    window.localStorage.removeItem("isLoggedIn");
    Store.dispatch(setLogin(false));
    Store.dispatch(setUser(null));
  } catch (e) {
    console.log(e);
  }
};
