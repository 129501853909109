import React from "react";
import {
  ArrowForward,
  MoreHorizOutlined,
  Subscriptions,
} from "@mui/icons-material";
import {
  Box,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
  Chip,
  Avatar,
} from "@mui/material";
import s1 from "../../../../assets/images/company-avatar-1.png";
import s2 from "../../../../assets/images/company-avatar-2.png";
import s3 from "../../../../assets/images/company-avatar-3.png";
import s4 from "../../../../assets/images/company-avatar-4.png";
import s5 from "../../../../assets/images/company-avatar-5.png";
import { CustomCard } from "../../../../components/common/CustomCard";
const products = [
  // {
  //   id: "PRD-000",
  //   name: "AWS",
  //   status: "Paid",
  //   icon: s0,
  //   cost: "$55 /year",
  // },

  {
    id: "PRD-005",
    name: "Supabase",
    icon: s5,
    status: "Paid",
    cost: "$55 /year",
  },
  {
    id: "PRD-004",
    name: "Vercel",
    icon: s4,
    status: "Paid",
    cost: "$55 /year",
  },
  {
    id: "PRD-003",
    name: "Auth0",
    icon: s3,
    status: "Expiring",
    cost: "$55 /year",
  },
  {
    id: "PRD-002",
    name: "Google Cloud",
    icon: s2,
    status: "Expiring",
    cost: "$55 /year",
  },
  {
    id: "PRD-001",
    name: "Stripe",
    status: "Canceled",
    icon: s1,
    cost: "$55 /year",
  },
];
export const OurSubscriptions = () => {
  return (
    <CustomCard sx={{ height: "450px" }}>
      <CardHeader
        title={<Typography variant="h6">Our Subscriptions</Typography>}
        avatar={
          <IconButton aria-label="subscriptions">
            <Subscriptions />
          </IconButton>
        }
      />
      {/* <Divider /> */}
      <Box
        sx={{ maxHeight: "320px", overflowY: "auto", scrollbarWidth: "none" }}
      >
        <List sx={{ p: "16px  24px " }}>
          {products.map((product, index) => (
            <ListItem disablePadding key={product.id}>
              <ListItemAvatar>
                <Avatar
                  src={product.icon}
                  alt="Product Icon"
                  sx={{
                    width: "40px",
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={product.name}
                primaryTypographyProps={{ variant: "subtitle1" }}
                secondary={product.cost}
                secondaryTypographyProps={{ variant: "body2" }}
              />
              {/* <Box
                ml={1}
                borderRadius={8}
                bgcolor={
                  product.status === "Paid"
                    ? "green"
                    : product.status === "Pending"
                    ? "orange"
                    : "#b80000"
                }
                pl={1.5}
                pr={1.5}
              >
                <Typography variant="overline" sx={{ color: "white" }}>
                  
                </Typography>
              </Box> */}
              <Chip
                label={product?.status ?? "Canceled"}
                sx={{
                  bgcolor:
                    product.status === "Paid"
                      ? "#CCFBEF" // Soft green
                      : product.status === "Expiring"
                      ? "#fff3c6" // Soft orange
                      : "#fee4e2", // Soft red

                  color:
                    product.status === "Paid"
                      ? "#107569" // Darker green for text
                      : product.status === "Expiring"
                      ? "#B84D05" // Darker orange for text
                      : "#bb241a", // Darker red for text
                }}
              />
              <IconButton edge="end">
                <MoreHorizOutlined weight="bold" />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={<ArrowForward />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </CardActions>
    </CustomCard>
  );
};
