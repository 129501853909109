import { useEffect, useState } from "react";
import "./common.scss";
import * as ReactDOM from "react-dom";

const ContentRenderer = ({ data }) => {
  useEffect(() => {
    const container = document.getElementById("html-element");
    container.insertAdjacentHTML("afterend", data.html);
  }, []);
  return (
    <div className="px-96">
      {/* <div className="font-semibold text-xl pt-3">{data.label}</div> */}
      {data.html ? <div id="html-element"></div> : <></>}
    </div>
  );
};
export default ContentRenderer;
