import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useState } from "react";
import { postRequest } from "../../utils/request";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postRequest("user/forgot-password", {
        email: email,
      });
      console.log(response);
      setSuccess("A link to reset password has been sent to your email");
    } catch (e) {
      setError(
        e.response.data.message
          ? e.response.data.message
          : "something went wrong"
      );
      console.log(e);
    }
  };
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h3">
            HealthBlox
          </Typography>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>

          {success ? (
            <Box sx={{ mt: 1 }}>
              <Typography
                mt={"20px"}
                align="center"
                // component="body"
                variant="body1"
              >
                {success}
              </Typography>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Button
                  // fullWidth
                  variant="contained"
                  onClick={() => {
                    setSuccess(null);
                  }}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Resend
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={handleSubmit}
              // noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(null);
                }}
                error={!!error}
                helperText={error}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Button
                disabled={email ? false : true}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>

              <Grid container>
                <Link href="/signup" variant="body2">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
              {/* {success && (
              <Typography
                mt={"20px"}
                align="center"
                component="body"
                variant="body1"
              >
                {success}
              </Typography>
            )} */}
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default ForgotPassword;
