import { Box, Stack, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ColumnNameTable } from "./ColumnNameTable";
import { getRequest, patchRequest, postRequest } from "../../utils/request";
import { useParams, useNavigate } from "react-router-dom";
import CustomTable from "./CustomTable";
import CustomButton from "../../components/common/CustomButton";
import Papa from "papaparse";
import { MappingTable } from "./MappingTable";
import { useAlert } from "../../services/context/AlertContext";

const EditSpace = ({
  type = "display",
  handleSelectCSV,
  spaceName = "",
  handleNewSpace,
  handleClose,
  initialStep = 1,
}) => {
  const navigate = useNavigate();
  const { handleAlert } = useAlert();
  const { spaceID } = useParams();
  const [step, setStep] = useState(initialStep);
  const [name, setName] = useState(spaceName);
  const [space, setSpace] = useState();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [csvColumns, setCSVColumns] = useState([]);
  const [csvId, setCSVId] = useState();
  const handleExit = () => {
    navigate("/custom-spaces");
  };
  const handleEditSpace = async (newfields, edit) => {
    setLoading(true);
    try {
      let response;
      if (!space || edit) {
        if (spaceID || space?._id) {
          const id = spaceID ?? space?._id;
          response = await patchRequest(
            `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customspaces/${id}`,
            { fields: newfields }
          );
          if (csvId) {
            await postRequest(
              `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customtable/csv/${space?._id}/${csvId}`,
              { mapping: newfields }
            );
          }
        } else {
          response = await postRequest(
            `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customspaces`,
            { common_name: name, fields: newfields }
          );
          handleNewSpace(response.data.customSpace);
        }
        console.log(response.data.customSpace?.fields);
        setSpace(response.data.customSpace);
        setFields(response.data.customSpace?.fields);
        setName(response.data.customSpace?.common_name);
      }
      setStep(2);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleImportCSV = (customspace) => {
    handleSelectCSV();
    setFields(customspace?.fields);
    setSpace(customspace);
  };
  const handleImportData = async (event) => {
    setLoading(true);
    console.log(loading);
    // setSelectedFile(event.target.files[0]);
    try {
      const selectedfile = event.target.files[0];
      const formData = new FormData();
      formData.append("file", selectedfile);
      const response = await postRequest(
        `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customTable/csv`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCSVColumns(response.data.columns);
      setCSVId(response.data.id);
      setStep(3);
    } catch (e) {
      console.log(e);
      handleAlert(e.response?.data?.message ?? "Something went wrong", "error");
    }
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const response = await getRequest(
          `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customspaces/${spaceID}`
        );
        setSpace(response.data?.customSpace);
        setFields(response.data?.customSpace?.fields);
        setName(response.data?.customSpace?.common_name);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    if (spaceID) fetchData();
    else {
      if (!fields.length > 0)
        setFields([{ display_name: "", key: "", type: "String" }]);
    }
    setLoading(false);
  }, []);

  return (
    <>
      {loading && (
        <Box
          width={"100%"}
          height={500}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress sx={{ color: "#CF7447" }} />
        </Box>
      )}
      {!loading && step === 1 && fields.length > 0 && (
        <ColumnNameTable
          type={type}
          handleEditSpace={handleEditSpace}
          handleSelectCSV={handleImportCSV}
          initialFields={fields}
          name={name}
          setCsvId={setCSVId}
        />
      )}
      {!loading && step === 2 && (
        <>
          <Stack spacing={2}>
            <CustomTable
              space={space}
              type={type}
              fields={fields}
              importDatafromCSV={handleImportData}
            />
            <Box display={"flex"} justifyContent={"space-between"} mt={2}>
              <CustomButton
                onClick={() => {
                  if (initialStep === 2) navigate("/custom-spaces");
                  else setStep(1);
                }}
              >
                BACK
              </CustomButton>
              <CustomButton onClick={handleClose ?? handleExit}>
                CLOSE
              </CustomButton>
            </Box>
          </Stack>
        </>
      )}
      {!loading && step === 3 && (
        <MappingTable
          type={type}
          fields={fields}
          columns={csvColumns}
          spaceId={spaceID ?? space?._id}
          csvId={csvId}
          handleNext={() => {
            setStep(2);
          }}
        />
      )}
    </>
  );
};

export default EditSpace;
