import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { postRequest } from "../../../utils/request";
import { useSelector } from "react-redux";
import { isTokenValid } from "../../../utils/sessionutils";
import { useAlert } from "../../../services/context/AlertContext";

export const EmailSetup = () => {
  const userData = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(userData?.user?.mfa_email ?? false);
  const [verified, setVerified] = useState(userData?.user?.mfa_email ?? false);
  const [otp, setOtp] = useState("");
  const { handleAlert } = useAlert();
  useEffect(() => {
    enable();
  }, []);
  const enable = async () => {
    // console.log(user.email);
    setLoading(true);
    try {
      await postRequest("mfa/generateotp", {
        email: userData.user.email,
        type: "EMAIL",
      });
      setEmail(true);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  const verifyOtp = async () => {
    try {
      const response = await postRequest("mfa/verifyotp", {
        token: otp,
        email: userData.user.email,
        type: "EMAIL",
      });
      console.log(response.data);
      setVerified(true);
      isTokenValid();
    } catch (err) {
      console.log(err);
      handleAlert("Could not verify, try again");
    }
  };
  return (
    <>
      {loading && <CircularProgress size="25px" />}
      {email &&
        (verified ? (
          <Alert severity="success">EMAIL authorization is enabled</Alert>
        ) : (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            m={2}
          >
            {email && (
              <Box
                display={"flex"}
                flexDirection={"row "}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  sx={{ verticalAlign: "bottom" }}
                >
                  <Typography marginX={"20px"}>Enter the otp:</Typography>
                  <TextField
                    value={otp}
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                    variant="standard"
                    sx={{ verticalAlign: "bottom" }}
                  ></TextField>
                </Box>
                <Button
                  sx={{ margin: "20px" }}
                  onClick={() => {
                    verifyOtp();
                  }}
                >
                  Verify
                </Button>
              </Box>
            )}
          </Box>
        ))}
    </>
  );
};
