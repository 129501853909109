import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import CustomInput, {
  CustomInputSelect,
} from "../../components/common/CustomInput";
import { useRef, useState } from "react";
import CustomButton from "../../components/common/CustomButton";
import { postRequest } from "../../utils/request";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { useAlert } from "../../services/context/AlertContext";

export const ColumnNameTable = ({
  name,
  type,
  handleEditSpace,
  handleSelectCSV,
  initialFields = [],
  setCsvId,
}) => {
  const { handleAlert } = useAlert();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const openFile = () => {
    fileInputRef.current.click();
  };
  const [fields, setFields] = useState([...initialFields]);
  const [edit, setEdit] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("name", name);
        console.log(formData);
        const response = await postRequest(
          `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customspaces/csv`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        handleSelectCSV(response.data?.customSpace);
        setFields(response.data?.customSpace?.fields);
        setCsvId(response.data?.id);
      } catch (e) {
        console.log(e);
        handleAlert(
          e.response?.data?.message ?? "Something went wrong",
          "error"
        );
      }
    }
  };
  const suggestKey = (value) => {
    let newKey = value
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "_")
      .replace(/[^\w\s]/gi, "");
    const existingKeys = fields.map((field) => field.key);
    while (existingKeys.includes(newKey)) {
      const randomHash = Math.floor(Math.random() * 10);
      newKey = newKey + randomHash;
    }
    return newKey;
  };
  const handleSuggestKey = (i, value) => {
    let tempFields = JSON.parse(JSON.stringify(fields));
    if (!tempFields[i]["key"]) {
      handleColumnChange(i, "key", suggestKey(value));
    }
  };
  const handleColumnChange = (i, field, newValue) => {
    let tempFields = JSON.parse(JSON.stringify(fields));
    if (field === "type" && newValue === "enum") {
      const enumInput = prompt(
        "Enter values for the enum array (comma-separated):"
      );
      if (enumInput) {
        tempFields[i]["enum"] = enumInput
          .split(",")
          .map((value) => value.trim());
      } else {
        tempFields[i]["enum"] = [];
      }
    }
    if (field == "enum") {
      tempFields[i]["enum"] = newValue.split(",").map((value) => value.trim());
    } else tempFields[i][field] = newValue;
    setFields(tempFields);
    setEdit(true);
  };

  const handleColumnAdd = () => {
    setFields((prevFields) => [
      ...prevFields,
      { display_name: "", type: "String", key: "" },
    ]);
    console.log(fields);
  };

  const handleDeleteField = (i) => {
    setEdit(true);
    let tempFields = [...fields];
    tempFields.splice(i, 1);
    setFields(tempFields);
  };

  return (
    <Stack spacing={2} p={2}>
      <Box
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">Create Fields</Typography>
        {type !== "csv" && (
          <CustomButton onClick={handleColumnAdd}>Insert Field</CustomButton>
        )}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Box
          sx={{ overflowY: "auto", maxHeight: type === "display" ? 600 : 400 }}
          p={3}
        >
          {fields.length > 0 ? (
            <TableContainer
              component={Paper}
              sx={{
                height: type === "display" ? 550 : 350,
                maxHeight: type === "display" ? 550 : 350,
                border: "1px solid #3F3F3F1A",
                borderRadius: 5,
                scrollbarWidth: "none",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key={"columnName"}
                      sx={{
                        textAlign: "center",
                        background: "#f9fafb",
                        color: "#667085",
                        fontWeight: "600",
                      }}
                    >
                      Column Name
                    </TableCell>
                    <TableCell
                      key={"key"}
                      sx={{
                        textAlign: "center",
                        background: "#f9fafb",
                        color: "#667085",
                        fontWeight: "600",
                      }}
                    >
                      Key
                    </TableCell>
                    <TableCell
                      key={"type"}
                      sx={{
                        textAlign: "center",
                        minWidth: 250,
                        background: "#f9fafb",
                        color: "#667085",
                        fontWeight: "600",
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      key={"actions"}
                      align="center"
                      style={{
                        textAlign: "center",
                        width: 100,
                        background: "#f9fafb",
                        color: "#667085",
                        fontWeight: "600",
                      }}
                      className={"sticky-actions-header"}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields &&
                    fields.length &&
                    fields.map((fieldName, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            fontSize: 16,
                            textAlign: "center",
                          }}
                        >
                          <Box>
                            <CustomInput
                              fullWidth
                              variant="standard"
                              value={fieldName?.display_name}
                              onChange={(e) =>
                                handleColumnChange(
                                  index,
                                  "display_name",
                                  e.target.value
                                )
                              }
                              onBlur={(e) =>
                                handleSuggestKey(index, e.target.value)
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 16,
                            textAlign: "center",
                          }}
                        >
                          <Box>
                            <CustomInput
                              disabled={
                                type === "csv" ||
                                // type == "display" ||
                                (initialFields.length > 0 &&
                                  initialFields[0].key)
                                  ? // || initialFields.length > 0
                                    true
                                  : false
                              }
                              fullWidth
                              variant="standard"
                              value={fieldName?.key}
                              onChange={(e) =>
                                handleColumnChange(index, "key", e.target.value)
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell sx={{ fontSize: 16, textAlign: "center" }}>
                          <Box>
                            {/* <Tooltip
                              placement="top-start"
                              title={
                                fieldName?.type === "enum" && (
                                  <CustomInput
                                    labelText={"enum values"}
                                    value={
                                      fields[index].enum
                                        ? fields[index].enum.join(", ")
                                        : " "
                                    }
                                    onChange={(e) =>
                                      handleColumnChange(
                                        index,
                                        "enum",
                                        e.target.value
                                      )
                                    }
                                    variant="outlined"
                                    fullWidth
                                  />
                                )
                              }
                            > */}
                            <CustomInputSelect
                              menuItems={[
                                { value: "String", label: "String" },
                                { value: "Number", label: "Number" },
                                { value: "Date", label: "Date" },
                                { value: "enum", label: "Enum" },
                              ]}
                              value={fieldName?.type}
                              onChange={(e) => {
                                handleColumnChange(
                                  index,
                                  "type",
                                  e.target.value
                                );
                                console.log(e.target.value);
                              }}
                            />
                            {fieldName?.type === "enum" && (
                              <CustomInput
                                fullWidth
                                variant="standard"
                                labelText={"Possible values"}
                                value={
                                  fields[index].enum
                                    ? fields[index].enum.join(", ")
                                    : " "
                                }
                                onChange={(e) =>
                                  handleColumnChange(
                                    index,
                                    "enum",
                                    e.target.value
                                  )
                                }
                              />
                            )}
                            {/* </Tooltip> */}
                          </Box>
                        </TableCell>
                        <TableCell
                          key={"actions"}
                          align="center"
                          sx={{ fontSize: 16, textAlign: "center" }}
                          className={"sticky-actions"}
                        >
                          <IconButton onClick={() => handleDeleteField(index)}>
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <div>
                <div className="text-center font-semibold text-lg py-3 shadow my-5 mx-96 rounded">
                  No Data Found
                </div>
              </div>
            </>
          )}
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} mt={2}>
          {/* {console.log(type, initialFields, typeof type)} */}
          {type === "display" ? (
            <CustomButton
              onClick={() => {
                navigate("/custom-spaces");
              }}
            >
              Back
            </CustomButton>
          ) : selectedFile ? (
            <CustomButton onClick={handleFileUpload}>Upload CSV</CustomButton>
          ) : (
            <CustomButton onClick={openFile}>Import CSV</CustomButton>
          )}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".csv"
            onChange={handleFileChange}
          />
          <CustomButton
            onClick={() => {
              handleEditSpace(fields, edit);
            }}
          >
            {edit ? "Save" : "Next"}
          </CustomButton>
        </Box>
      </Box>
    </Stack>
  );
};
