import { useState, useEffect } from "react";
import "./common.scss";

const TextField = ({ data, setAnswer, formValidation = true }) => {
  const [selectedOption, setSelectedOption] = useState(
    data?.answer?.value || ""
  );
  const [validForm, setFormValidation] = useState(formValidation);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
    }
  }, [formValidation]);

  return (
    <div className=" w-full">
      {data?.label && (
        <div className="form-title pt-3">
          {data.label}{" "}
          {data?.validate?.required ? (
            <span className={!validForm ? "text-red-700" : ""}> * </span>
          ) : (
            <></>
          )}
        </div>
      )}
      <form>
        <div className="radio-common my-2">
          <input
            type="text"
            value={selectedOption}
            onChange={(e) => {
              setSelectedOption(e.target.value);
              setAnswer && setAnswer(data, { value: e.target.value });
              setFormValidation(true);
            }}
            className="text-field text-input"
          />
        </div>
      </form>

      {data?.validate?.required && !validForm ? (
        <span className="text-red-700 font-light text-xs">
          {" "}
          Required Field{" "}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
export default TextField;
