import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setAppointment } from "../../../services/redux/action";

export default function AppointmentsTable({ data, next }) {
  const filteredData = data.map(
    ({ patient, custom, appointmentNotes, ...rest }) => ({
      firstName: patient["firstName"],
      lastName: patient["lastName"],
      ...rest,
    })
  );
  const dispatch = useDispatch();
  const columns = Object.keys(filteredData[0]);
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: 400, overflow: "auto" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column}
                style={{
                  position: "sticky",
                  top: 0,
                  background: "white",
                  zIndex: 1,
                }}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row, rowIndex) => (
            <TableRow
              style={{ cursor: "pointer" }}
              hover
              key={rowIndex}
              onClick={() => {
                dispatch(setAppointment(data[rowIndex]));
                next();
              }}
            >
              {columns.map((column, colIndex) => (
                <TableCell key={colIndex}>{row[column]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
