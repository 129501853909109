import { useState, useEffect } from "react";
import "./common.scss";

const RadioSelector = ({ data, setAnswer, formValidation }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [validForm, setFormValidation] = useState(formValidation);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
    }
  }, [formValidation]);

  return (
    <div className=" w-full">
     {data?.label && <div className="form-title pt-3">
        {data.label}
        {data?.validate?.required ? (
          <span className={!validForm ? "text-red-700" : ""}> * </span>
        ) : (
          <></>
        )}
        {data?.validate?.required && !validForm ? (
          <span className="text-red-700 font-light text-xs">
            {" "}
            Required Field{" "}
          </span>
        ) : (
          <></>
        )}
      </div> }
      {data?.values && data?.values?.length ? (
        <>
          <div className="">
            {data.values.map((item) => {
              return (
                <div
                  className="radio-common custom-radio-selector my-3 "
                >
                  <label className="pl-2">
                    <input
                      type="radio"
                      value={item.value}
                      checked={selectedOption === item.value}
                      className="mr-2 custom-radio"
                      id="box-shadow" 
                      onClick={() => {
                        setSelectedOption(item.value);
                        setAnswer && setAnswer(data, item);
                        setFormValidation(true);
                      }}
                    />
                    <span className="form-title">{item.label}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default RadioSelector;
