import {
  Box,
  CardHeader,
  CircularProgress,
  Stack,
  Typography,
  CardContent,
  Table,
  TableRow,
  TableCell,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../utils/request";
import { useSelector } from "react-redux";
import { isTokenValid } from "../../../utils/sessionutils";
import { useAlert } from "../../../services/context/AlertContext";
import { CustomCard } from "../../../components/common/CustomCard";
import CustomButton from "../../../components/common/CustomButton";
import { SwapHoriz } from "@mui/icons-material";

export const SwitchOrg = () => {
  const [data, setData] = useState(null);
  const getOrgs = async () => {
    try {
      const response = await getRequest("user/organizations");
      setData(response.data.orgs);
      console.log(response.data.orgs);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    isTokenValid();
    getOrgs();
  }, []);
  return (
    <Stack spacing={4}>
      <Typography variant="h4">Switch Organization</Typography>
      <CustomCard sx={{ p: 2 }}>
        <CardHeader
          title={"Your Organizations"}
          titleTypographyProps={{ variant: "h6" }}
          avatar={<SwapHoriz />}
        />
        <CardContent>
          <Stack spacing={2}>
            <Table>
              {data &&
                data.map((org, index) => <OrgCard org={org} index={index} />)}
            </Table>
          </Stack>
        </CardContent>
      </CustomCard>
    </Stack>
  );
};

const OrgCard = (org, index) => {
  const { handleLoader } = useAlert();
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.user);
  const switchOrg = async () => {
    // setLoading(true);
    try {
      const response = await postRequest(
        "user/organization/switch",
        { id: org.org.id },
        {
          withCredentials: true,
        }
      );
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      window.location.href = "/organization_settings";
      console.log(response);
    } catch (e) {
      console.log(e);
    }
    // setLoading(false);
  };
  return (
    <TableRow>
      <TableCell>
        <Typography>{org.org.name}</Typography>
      </TableCell>
      <TableCell align="right">
        <CustomButton
          disabled={org.org.id === userData?.org?._id || loading}
          key={org.index}
          onClick={() => handleLoader(switchOrg)}
        >
          {loading ? (
            <CircularProgress />
          ) : org.org.id === userData?.org?._id ? (
            "current"
          ) : (
            "Switch"
          )}
        </CustomButton>
      </TableCell>
    </TableRow>
    // <CustomCard>
    //   {/* <Box
    //     key={"box" + org.index}
    //     display={"flex"}
    //     flexDirection={"row"}
    //     justifyContent={"space-between"}
    //   > */}
    //   <CardHeader
    //     sx={{ p: 3 }}
    //     title={org.org.name}
    //     titleTypographyProps={{ variant: "body1" }}
    //     action={
    //       <CustomButton
    //         disabled={org.org.id === userData?.org?._id || loading}
    //         key={org.index}
    //         onClick={() => handleLoader(switchOrg)}
    //       >
    //         {loading ? (
    //           <CircularProgress size={25} />
    //         ) : org.org.id === userData?.org?._id ? (
    //           "current"
    //         ) : (
    //           "Switch Organization"
    //         )}
    //       </CustomButton>
    //     }
    //   />
    //   {/* <Typography key={"text" + index}>{org.org.name}</Typography>
    //     <Button
    //       disabled={org.org.id === userData?.org?._id || loading}
    //       key={org.index}
    //       onClick={() => handleLoader(switchOrg)}
    //     >
    //       {loading ? (
    //         <CircularProgress />
    //       ) : org.org.id === userData?.org?._id ? (
    //         "current"
    //       ) : (
    //         "Switch Organization"
    //       )}
    //     </Button> */}
    //   {/* </Box> */}
    // </CustomCard>
  );
};
