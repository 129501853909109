import { useState, useEffect } from "react";
import "./common.scss";

const Email = ({
  data,
  setAnswer,
  formValidation = true,
  formClass,
  invalidMessage,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [validForm, setFormValidation] = useState(formValidation);

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedOption(data.answer?.value);
    }
  }, [formValidation]);

  return (
    <div className={`w-full ${formClass}`}>
      <div className="form-title pt-3">
        {data.label}
        {data?.validate?.required ? (
          <span className={!validForm ? "text-red-700" : ""}> * </span>
        ) : (
          <></>
        )}
      </div>

      <form className="flex-grow">
        <div className="radio-common my-2">
          <label className="w-full">
            <input
              type="email"
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.target.value);
                setAnswer(data, { value: e.target.value });
                setFormValidation(true);
              }}
              className="text-field text-input"
            />
          </label>
        </div>
      </form>
      {data?.validate?.required && !validForm ? (
        <span className="text-red-700 font-light text-xs">
          {invalidMessage || "Required Field"}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};
export default Email;
