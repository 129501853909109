import React, { useState, useEffect } from "react";
import "./common.scss";

import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableContent from "./TableContent";

const TableLayout = ({
  columns = [],
  rows = [],
  filters = [],
}) => {
  const [tableData, setTableData] = useState(null);
  const [activeFilter, setActiveFilter] = useState("ALL");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const countCheckForFilters = ({ id, filterKey }) => {
    let totalCount = 0;
    rows.forEach((item) => {
      if (item[filterKey] === id || id === "ALL") {
        totalCount++;
      }
    });
    return totalCount;
  };

  const handleFilterClick = (filterID) => {
    setActiveFilter(filterID);
    if(filterID === 'ALL') setTableData(rows)
    else {
      let updatedTableData = rows.filter(
        (item) => item?.status === filterID
      );
      setTableData(updatedTableData);
  }
  };

  useEffect(() => {
    if(activeFilter) handleFilterClick(activeFilter)
    else setTableData(rows);
  }, [activeFilter, rows]);

  return (
    <div className="table-layout">
      {tableData ? (
        <>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              borderRadius: "16px",
              border: "1px",
              borderColor: "inherit",
              border: "solid",
              boxShadow: "none",
            }}
          >
            {filters && filters.length !== 0 ? (
              <>
                <div className="flex flex-row border-bottom px-3 relative">
                  {filters.map((filterItem) => {
                    return (
                      <>
                        <div
                          className="flex flex-col"
                          onClick={() => handleFilterClick(filterItem.id)}
                        >
                          <div
                            className={`py-3 px-1 cursor-pointer text-md font-semibold text-gray-500  ${
                              activeFilter === filterItem.id
                                ? "purple-text"
                                : "hover:text-gray-800"
                            }`}
                          >
                            {filterItem.label}
                            <span className="ml-2 border rounded-full bg-gray-200 px-1 text-sm">
                              {countCheckForFilters(filterItem)}
                            </span>
                          </div>
                          <div
                            className={`${
                              activeFilter === filterItem.id
                                ? "active-filter"
                                : ""
                            }`}
                          ></div>
                        </div>
                        <div className="mx-3"></div>
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
            <TableContent
              rows={tableData}
              columns={columns}
              rowsPerPage={rowsPerPage}
              page={page}
            />
            {tableData && tableData.length ? <>
            <TablePagination
              rowsPerPageOptions={[5, 10, 30]}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="m-auto"
            />
            </> : <></>}
          </Paper>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default TableLayout;
