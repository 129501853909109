import { useRef, useEffect, useState, useContext } from "react";
import { Handle, Position } from "reactflow";
import { FaPlus } from "react-icons/fa6";
import "./customNodes.scss";
import { GrConfigure } from "react-icons/gr";
import { FaCheck } from "react-icons/fa";
import { FaExclamation } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { useReactFlow, NodeToolbar } from "reactflow";
import TextField from "../../../components/common/TextField";
import DropDown from "../../../components/common/dropdown";
import CustomButton from "../../../components/common/CustomButton";
import SingleCheckBox from "../../../components/common/SingleCheckBox";
import RadioSelector from "../../../components/common/RadioSelector";
import { MdDelete } from "react-icons/md";
import PopUpDrawer from "../popup-box";
import CareFlowContext from "../../../services/context/CareFlowContext";
const paramCharacters = [
  ".",
  ",",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
function CustomNode(node) {
  const { data } = node;
  const activeNodeId = node?.id;

  const [showPopUp, setShowPopup] = useState(false);
  const [configurations, setConfigurations] = useState(data?.configurations);
  const [configsList, setConfigsList] = useState([]);
  const [isConfigured, setIsConfigured] = useState(false);
  const [showPopupForNewApp, setShowPopupForNewApp] = useState(false);
  const [originalParamOptions, setOriginalParamOptions] = useState([
    { value: "$ehrApi1", label: "$ehrApi1" },
    { value: "$ehrApi2", label: "$ehrApi2" },
  ]);
  const [dotParamOptions, setDotParamOptions] = useState([
    { value: ".appointmentid", label: ".appointmentid", selector: "$ehrApi1" },
    { value: ".patientid", label: ".patientid", selector: "$ehrApi1" },
    {
      value: ".appointment_date",
      label: ".appointment_date",
      selector: "$ehrApi1",
    },
    { value: ".checkin_time", label: ".checkin_time", selector: "$ehrApi1" },
    { value: ".checkout_time", label: ".checkout_time", selector: "$ehrApi1" },
    { value: ".notes", label: ".notes", selector: "$ehrApi1" },
    { value: ".physicianid", label: ".physicianid", selector: "$ehrApi1" },
    { value: ".diagnosis", label: ".diagnosis", selector: "$ehrApi1" },
    { value: ".HPI", label: ".HPI", selector: "$ehrApi1" },
    { value: ".others", label: ".others", selector: "$ehrApi1" },
    { value: ".patientid", label: ".patientid", selector: "$ehrApi2" },
    { value: ".firstName", label: ".firstName", selector: "$ehrApi2" },
    { value: ".lastName", label: ".lastName", selector: "$ehrApi2" },
    { value: ".dob", label: ".dob", selector: "$ehrApi2" },
    { value: ".address", label: ".address", selector: "$ehrApi2" },
    { value: ".SSN", label: ".SSN", selector: "$ehrApi2" },
    { value: ".insurance", label: ".insurance", selector: "$ehrApi2" },
    { value: ".custom_fields", label: ".custom_fields", selector: "$ehrApi2" },
    {
      value: ".last_appointment",
      label: ".last_appointment",
      selector: "$ehrApi2",
    },
  ]);
  const [paramsOption, setParamsOption] = useState([]);

  function checkForConfigure(configData) {
    let configStatus = true;
    // for (let i = 0; i < configData?.length; i++) {
    //   if (checkForDependentQues(configData[i]) && configData[i].required && !configData[i].answer) configStatus = false;
    // }
    setIsConfigured(configStatus);
  }

  const ref = useRef(null);
  const onClickOutside = () => {
    setShowPopup(false);
    setShowPopupForNewApp(false);
  };
  const { setNodes, setEdges } = useReactFlow();
  const {
    conditionData,
    setConditionData,
    history,
    setHistory,
    historyStep,
    setHistoryStep,
  } = useContext(CareFlowContext);

  function handleInternalNodeClick(type) {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === activeNodeId) {
          node.clickType = type;
        }
        return node;
      })
    );
  }

  // function resetDependentQuestions(configData) {
  //   debugger
  //   let updatedConfigData = configData.map((config)=> {
  //     if(config?.dependentOn) {
  //       let dependentValue = config?.dependentValue
  //       let parentQues = configData.filter(config=> config?.quesKey === config.dependentOn)[0]
  //       if(parentQues && parentQues?.answer &&parentQues.answer.value){}
  //     }
  //   })

  // }

  function updateConfigurations(index, data, textFieldValue) {
    let configData = JSON.parse(JSON.stringify(configsList));
    if (textFieldValue) {
      configData[index].answer = textFieldValue?.value || "";
    } else {
      configData[index].answer = data;
    }
    // let updatedConfigData = resetDependentQuestions(configData)
    setConfigsList(configData);
  }
  function handleParamsConfigChanges(configIndex, paramIndex, event) {
    let { value } = event.target;
    let configData = JSON.parse(JSON.stringify(configsList));
    configData[configIndex].answer[paramIndex].value = value || "";
    setConfigsList(configData);
  }
  function handleShowParamsOptions(configIndex, paramIndex, event) {
    debugger;
    let configData = JSON.parse(JSON.stringify(configsList));
    if (event?.key === "$") {
      configData[configIndex].answer[paramIndex].showParamOptions = true;
      configData[configIndex].answer[paramIndex].paramTypedValue = "";
    }
    if (event?.key === ".") {
      configData[configIndex].answer[
        paramIndex
      ].showParamOptionsDotSelector = true;
      configData[configIndex].answer[paramIndex].dotTypedValue = "";
    }
    if (configData[configIndex].answer[paramIndex]?.showParamOptions) {
      if (event.key === "Backspace") {
        if (
          configData[configIndex].answer[paramIndex].paramTypedValue === "$"
        ) {
          configData[configIndex].answer[paramIndex].showParamOptions = false;
          configData[configIndex].answer[paramIndex].paramTypedValue = "";
        } else
          configData[configIndex].answer[paramIndex].paramTypedValue =
            configData[configIndex].answer[paramIndex].paramTypedValue?.slice(
              0,
              -1
            );
      } else if (paramCharacters.includes(event.key)) {
        configData[configIndex].answer[paramIndex].paramTypedValue += event.key;
      }
      let filteredParamOptions = originalParamOptions.filter((option) => {
        return option.value?.includes(
          configData[configIndex].answer[paramIndex].paramTypedValue
        );
      });
      setParamsOption(filteredParamOptions);
    }
    if (
      !configData[configIndex].answer[paramIndex]?.showParamOptions &&
      configData[configIndex].answer[paramIndex]?.showParamOptionsDotSelector
    ) {
      if (event.key === "Backspace") {
        if (configData[configIndex].answer[paramIndex].dotTypedValue === ".") {
          configData[configIndex].answer[paramIndex].dotTypedValue = "";
          configData[configIndex].answer[
            paramIndex
          ].showParamOptionsDotSelector = "";
        } else
          configData[configIndex].answer[paramIndex].dotTypedValue = configData[
            configIndex
          ].answer[paramIndex].dotTypedValue?.slice(0, -1);
      } else if (paramCharacters.includes(event.key)) {
        configData[configIndex].answer[paramIndex].dotTypedValue += event.key;
      }
      let filteredParamOptions = dotParamOptions.filter(
        (option) =>
          option.value?.includes(
            configData[configIndex].answer[paramIndex].dotTypedValue
          ) &&
          option.selector ===
            (configData[configIndex].answer[paramIndex]?.selectedParam || "")
      );
      setParamsOption(filteredParamOptions);
    }
    setConfigsList(configData);
  }
  function handleOptionClickForParams(configIndex, paramIndex, value) {
    let configData = JSON.parse(JSON.stringify(configsList));
    if (configData[configIndex].answer[paramIndex].showParamOptions) {
      configData[configIndex].answer[paramIndex].showParamOptions = false;
      configData[configIndex].answer[paramIndex].value = configData[
        configIndex
      ].answer[paramIndex].value.replace(
        configData[configIndex].answer[paramIndex].paramTypedValue,
        value
      );
      configData[configIndex].answer[paramIndex].paramTypedValue = "";
      configData[configIndex].answer[paramIndex].selectedParam = value;
    } else if (
      configData[configIndex].answer[paramIndex].showParamOptionsDotSelector
    ) {
      configData[configIndex].answer[
        paramIndex
      ].showParamOptionsDotSelector = false;
      configData[configIndex].answer[paramIndex].value = configData[
        configIndex
      ].answer[paramIndex].value.replace(
        configData[configIndex].answer[paramIndex].dotTypedValue,
        value
      );
      configData[configIndex].answer[paramIndex].dotTypedValue = "";
    }

    setConfigsList(configData);
  }
  function handleSaveConfigurations() {
    let allNodesList = [];
    setNodes((nds) => {
      allNodesList = [...nds];
      return nds;
    });
    let updatedNodesList = JSON.parse(JSON.stringify(allNodesList));
    for (let i = 0; i < updatedNodesList?.length; i++) {
      if (updatedNodesList[i].id === activeNodeId) {
        updatedNodesList[i].data.configurations.configData = configsList;
      }
    }
    setNodes(updatedNodesList);
    checkForConfigure(configsList);
    setShowPopup(false);
  }

  const handleAppClick = (data) => {
    let nodesList, edgesList;
    setNodes((nds) => {
      nds.map((node) => {
        if (node.id === activeNodeId) {
          node.data = data;
        }
        return node;
      });
      nodesList = nds;
      return nds;
    });
    setEdges((eds) => {
      edgesList = eds;
      return eds;
    });
    setHistory((prev) => [...prev, { nodes: nodesList, edges: edgesList }]);
    setHistoryStep((prev) => prev + 1);
    setShowPopupForNewApp(false);
  };

  function checkForDependentQues(config) {
    let flag = true;
    let dependentOnDataArray = config?.dependentOn;

    dependentOnDataArray?.forEach((dependencyObject) => {
      const { dependentOnQuesKey } = dependencyObject;
      const { dependentValue } = dependencyObject;

      let questionKeyIndex = configsList.findIndex(
        (item) => item.quesKey === dependentOnQuesKey
      );
      if (questionKeyIndex === -1) {
        flag = false;
        return flag;
      } else {
        if (
          !(
            configsList[questionKeyIndex]?.answer &&
            configsList[questionKeyIndex].answer?.value &&
            dependentValue.includes(configsList[questionKeyIndex].answer.value)
          )
        ) {
          flag = false;
          return flag;
        }
      }
    });

    return flag;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    if (data && data?.configurations) setConfigurations(data.configurations);
    if (data && data?.configurations && data.configurations?.configData) {
      setConfigsList(data.configurations.configData);
      checkForConfigure(data.configurations.configData);
    } else checkForConfigure(null);
  }, [node]);
  return (
    <div className="custom-nodes">
      <NodeToolbar
        isVisible={showPopUp || showPopupForNewApp}
        position={Position.Right}
        style={{
          width: "320px",
          minHeight: "400px",
        }}
      >
        {configurations && showPopUp && (
          <div ref={ref}>
            <div className="pop-up-draw-nodes w-full h-full">
              <div className="h-full">
                <div className="flex flex-col h-full">
                  <div className="flex-none title-box text-white px-2 flex justify-between	">
                    <div className="px-1">
                      {data?.label || "Configurations"}
                    </div>
                    <div>
                      <IoIosClose
                        className="text-black text-3xl cursor-pointer"
                        onClick={() => setShowPopup(false)}
                      />
                    </div>
                  </div>
                  <div className="grow content-box">
                    {configurations && configsList && configsList?.length ? (
                      <>
                        {configsList?.map((config, index) => {
                          return (
                            <>
                              {config?.dependentOn &&
                              config?.dependentOn?.length ? (
                                <>
                                  {checkForDependentQues(config) && (
                                    <>
                                      <div className="condition-form">
                                        <div className="flex justify-start">
                                          {config?.ques && (
                                            <div className="content-title">
                                              {config.ques}
                                            </div>
                                          )}
                                        </div>
                                        {config?.type === "text" && (
                                          <TextField
                                            className="w-full"
                                            data={{
                                              answer: {
                                                value: config?.answer || "",
                                              },
                                            }}
                                            setAnswer={(data, textFieldValue) =>
                                              updateConfigurations(
                                                index,
                                                data,
                                                textFieldValue
                                              )
                                            }
                                          />
                                        )}

                                        {config?.type === "dropdown" && (
                                          <div className="my-2">
                                            <DropDown
                                              data={{
                                                options: config?.options,
                                                answer: config?.answer,
                                                badge: config?.badge,
                                              }}
                                              className="input-style"
                                              placeholder={config?.placeholder}
                                              setAnswer={(data) =>
                                                updateConfigurations(
                                                  index,
                                                  data
                                                )
                                              }
                                            />
                                          </div>
                                        )}

                                        {config?.type === "checkbox" && (
                                          <SingleCheckBox
                                            data={{
                                              label: config?.key,
                                              key: config?.key,
                                              answer: config?.answer,
                                            }}
                                            // setAnswer={(data, answer) =>
                                            //   updateConfigurations(index, data, answer)
                                            // }
                                          />
                                        )}
                                        {config?.type === "radio" && (
                                          <RadioSelector
                                            data={{
                                              values: config?.options,
                                              answer: config?.answer,
                                            }}
                                          />
                                        )}
                                        {config?.type === "params" ? (
                                          <>
                                            <div className="grid grid-cols-12 gap-2">
                                              <div className="col-span-5 px-2 mustard_text font-semibold">
                                                Key
                                              </div>
                                              <div className="col-span-7 px-2 mustard_text font-semibold">
                                                Value
                                              </div>
                                            </div>
                                            {config?.answer?.map((item, i) => {
                                              return (
                                                <div className="grid grid-cols-12 gap-2 my-2">
                                                  <div className="col-span-5 flex">
                                                    <div className="my-auto font-semibold text-gray-600">
                                                      {item.key}
                                                    </div>
                                                    {item?.required ? (
                                                      <div className="text-red-800 text-xl my-auto">
                                                        *
                                                      </div>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                  <div className="col-span-7 relative">
                                                    <input
                                                      type="text"
                                                      value={item.value}
                                                      onBlur={(e) => {
                                                        // only re-focus if the user clicked on something
                                                        // that was NOT an input element
                                                        if (
                                                          e.relatedTarget ===
                                                          null
                                                        ) {
                                                          e.target.focus();
                                                        }
                                                      }}
                                                      placeholder="value"
                                                      className="text-field text-input"
                                                      onChange={(e) => {
                                                        handleParamsConfigChanges(
                                                          index,
                                                          i,
                                                          e
                                                        );
                                                      }}
                                                      onKeyUp={(e) => {
                                                        handleShowParamsOptions(
                                                          index,
                                                          i,
                                                          e
                                                        );
                                                      }}
                                                    />
                                                    {(item?.showParamOptions ||
                                                      item?.showParamOptionsDotSelector) &&
                                                    paramsOption &&
                                                    paramsOption?.length ? (
                                                      <>
                                                        <div className="custom-dropdown-option">
                                                          {paramsOption.map(
                                                            (option) => {
                                                              return (
                                                                <option
                                                                  onClick={() => {
                                                                    handleOptionClickForParams(
                                                                      index,
                                                                      i,
                                                                      option.value
                                                                    );
                                                                  }}
                                                                  value={
                                                                    option.value
                                                                  }
                                                                  className="custom-option"
                                                                >
                                                                  {option.label}
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <div className="condition-form">
                                  <div className="flex justify-start">
                                    {config?.ques && (
                                      <div className="content-title">
                                        {config.ques}
                                      </div>
                                    )}
                                  </div>
                                  {config?.type === "text" && (
                                    <TextField
                                      className="w-full"
                                      data={{
                                        answer: {
                                          value: config?.answer || "",
                                        },
                                      }}
                                      setAnswer={(data, textFieldValue) =>
                                        updateConfigurations(
                                          index,
                                          data,
                                          textFieldValue
                                        )
                                      }
                                    />
                                  )}

                                  {config?.type === "dropdown" && (
                                    <div className="my-2">
                                      <DropDown
                                        data={{
                                          options: config?.options,
                                          answer: config?.answer,
                                          badge: config?.badge,
                                        }}
                                        className="input-style"
                                        placeholder={config?.placeholder}
                                        setAnswer={(data) =>
                                          updateConfigurations(index, data)
                                        }
                                      />
                                    </div>
                                  )}

                                  {config?.type === "checkbox" && (
                                    <SingleCheckBox
                                      data={{
                                        label: config?.key,
                                        key: config?.key,
                                        answer: config?.answer,
                                      }}
                                    />
                                  )}
                                  {config?.type === "radio" && (
                                    <RadioSelector
                                      data={{
                                        values: config?.options,
                                        answer: config?.answer,
                                      }}
                                    />
                                  )}
                                  {config?.type === "params" ? (
                                    <>
                                      <div className="grid grid-cols-12 gap-2">
                                        <div className="col-span-5 px-2 mustard_text font-semibold">
                                          Key
                                        </div>
                                        <div className="col-span-7 px-2 mustard_text font-semibold">
                                          Value
                                        </div>
                                      </div>
                                      {config?.answer?.map((item, i) => {
                                        return (
                                          <div className="grid grid-cols-12 gap-2 my-2">
                                            <div className="col-span-5 flex">
                                              <div className="my-auto font-semibold text-gray-600">
                                                {item.key}
                                              </div>
                                              {item?.required ? (
                                                <div className="text-red-800 text-xl my-auto">
                                                  *
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            <div className="col-span-7">
                                              <input
                                                type="text"
                                                value={item.value}
                                                placeholder="value"
                                                className="text-field text-input"
                                                // onChange={(e) => {
                                                //   handleParamsConfigChanges(
                                                //     index,
                                                //     i,
                                                //     e
                                                //   );
                                                // }}
                                              />
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="px-2 flex gap-2 pop-up-footer justify-end">
                    <CustomButton
                      variant={"outlined"}
                      size="small"
                      onClick={() => setShowPopup(false)}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      size="small"
                      onClick={handleSaveConfigurations}
                    >
                      Save
                    </CustomButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showPopupForNewApp && (
          <PopUpDrawer
            onClickOutside={() => {
              setShowPopupForNewApp(false);
            }}
            handleAppClick={handleAppClick}
          />
        )}
      </NodeToolbar>
      {data && data.nodeType === "empty-node" ? (
        <div className="start-node" onClick={() => setShowPopupForNewApp(true)}>
          <Handle
            type="source"
            position={Position.Right}
            className="opacity-0"
          />
          <div
            className={`start-icon ${
              node.id !== "start-node" ? " gray-icon" : ""
            } ${
              node.selected
                ? node.id === "start-node"
                  ? "icon-clicked"
                  : "gray-clicked"
                : ""
            }`}
          >
            <FaPlus />
          </div>
          <Handle
            type="target"
            position={Position.Left}
            className="opacity-0"
          />
        </div>
      ) : (
        <div className="app-node">
          <Handle
            type="source"
            position={Position.Right}
            className="opacity-0"
          />
          <div className="flex gap-1">
            <div className="flex  flex-col node-wrapper gap-2">
              <div className="flex gap-4 justify-center">
                {/* {data?.Icon && <div className="icon">{data.Icon}</div>} */}
                {data?.label && <div className="label">{data.label}</div>}
              </div>
              <div className="flex justify-between">
                <div>
                  <MdDelete
                    className="text-red-600 cursor-pointer text-xl"
                    onClick={() => handleInternalNodeClick("delete-node")}
                  />
                </div>
                {data?.configurations && (
                  <div className="configured-label flex">
                    <div>
                      <GrConfigure
                        onClick={() => setShowPopup(!showPopUp)}
                        className={
                          isConfigured
                            ? "text-green-700 cursor-pointer text-xl"
                            : "text-red-700 cursor-pointer text-xl"
                        }
                      />
                    </div>
                    {isConfigured ? (
                      <FaCheck className="text-green-700 mx-1" />
                    ) : (
                      <FaExclamation className="text-red-700 mx-1" />
                    )}
                  </div>
                )}
              </div>
            </div>
            {data && data.nodeType !== "end-node" && (
              <div
                className="add-icon"
                onClick={() => handleInternalNodeClick("add-node")}
              >
                <FaPlus />
              </div>
            )}
          </div>
          <Handle
            type="target"
            position={Position.Left}
            className="opacity-0"
          />
        </div>
      )}
    </div>
  );
}

export default CustomNode;
