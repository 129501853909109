import {
  Business,
  Logout,
  Person,
  //   PersonAdd,
  //   Settings,
} from "@mui/icons-material";
import { Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { endSession } from "../utils/sessionutils";
import { useAlert } from "../services/context/AlertContext";
export const SettingsMenu = ({ anchorEl, handleClose }) => {
  const { handleLoader } = useAlert();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflowY: "scroll",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem disabled>
        <ListItemIcon>
          <Person fontSize="small" />
        </ListItemIcon>{" "}
        User Settings
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/user_settings");
        }}
      >
        User details
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/user_settings/security");
        }}
      >
        Security
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/user_settings/notifications");
        }}
      >
        Notifications
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/user_settings/activity");
        }}
      >
        Activity
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/user_settings/role");
        }}
      >
        Role & Permission
      </MenuItem>
      <Divider />
      <MenuItem disabled>
        <ListItemIcon>
          <Business fontSize="small" />
        </ListItemIcon>
        Organization Settings
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/organization_settings/");
        }}
      >
        Organization info
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/organization_settings/members");
        }}
      >
        Members
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/organization_settings/teams");
        }}
      >
        Teams
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/organization_settings/activity");
        }}
      >
        Activity
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/organization_settings/payments");
        }}
      >
        Payments
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/organization_settings/switch_organization");
        }}
      >
        Switch Organization
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleLoader(async () => endSession())}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
};
