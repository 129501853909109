// import React, { useState, useEffect } from "react";
// import fileData from "../../assets/test-file.json";
import Papa from "papaparse";
import axios from "axios";
// import CanvasJSReact from '@canvasjs/react-charts';
// import {
//     LineChart,
//     Line,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip
//   } from "recharts";

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// const Charts = () => {
//   const [chartData, setChartData] = useState([]);
//   const [options, setOptions] = useState(null);
//   useEffect(() => {
//     // const fetchCSV = async () => {
//     //     debugger
//     //   const response = await fetch(chartData);
//     //   const reader = response.body.getReader();
//     //   const result = await reader.read();
//     //   const decoder = new TextDecoder('utf-8');
//     //   const csv = decoder.decode(result.value);
//     //   Papa.parse(csv, {
//     //     header: true,
//     //     complete: (results) => {
//     //       console.log(results.data);
//     //     },
//     //   });
//     // };

//     // fetchCSV();
//     let data = [];
//     for (let i = 0; i < fileData.length; i++) {
//       data.push({ x: i, y: fileData[i] });
//     }
//     console.log(data)
//     setChartData([...data]);
//     setOptions({
//         zoomEnabled: true,
//         animationEnabled: true,
//         title: {
//           text: "Try Zooming - Panning"
//         },
//         data: [{
//             type: "line",
//             dataPoints: data
//           }]
//       })
//   }, []);

//   return (
//     <div className="container sample">
//       hello
//       <div>
//         {/* <LineChart
//           width={500}
//           height={200}
//           data={chartData}
//           margin={{
//             top: 10,
//             right: 30,
//             left: 0,
//             bottom: 0,
//           }}
//         >
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="time" />
//           <YAxis type="number" domain={[60, 100]}/>
//           <Tooltip />
//           <Line type="monotone" dataKey="spo" stroke="#8884d8" fill="#8884d8" />
//         </LineChart> */}
//         <CanvasJSChart options={options} onRef={chartRef => (chartRef = chartRef)} />
//       </div>
//     </div>
//   );
// };

// export default Charts;

import React, { useState, useEffect, useRef } from "react";
import { IgrCategoryChart } from "igniteui-react-charts";
import { IgrCategoryChartModule } from "igniteui-react-charts";
import { CircularProgress } from "@mui/material";

import { IgrFinancialChart, MarkerType } from "igniteui-react-charts";
import { IgrFinancialChartModule } from "igniteui-react-charts";
import { StocksUtility } from "./StocksUtility";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

IgrFinancialChartModule.register();

IgrCategoryChartModule.register();

const Charts = () => {
  const [dataSource, setDataSource] = useState([]);
  const [csvUrl, setCsvUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [calloutsVisible, setCalloutsVisible] = useState(true);
  const [crosshairsMode, setCrosshairsMode] = useState("Both");
  const [crosshairsVisible, setCrosshairsVisible] = useState(true);
  const [finalValuesVisible, setFinalValuesVisible] = useState(true);
  const [markersVisible, setMarkersVisible] = useState(false);
  const [markerTypes, setMarkerTypes] = useState([MarkerType.None]);
  const [toolTipVisible, setToolTipVisible] = useState(true);
  const [toolTipType, setToolTipType] = useState("Item");
  const [data, setData] = useState([]);
  const chartRef = useRef(null);

  const amchartRef = useRef(null);

  // useEffect(() => {
  //   let root = am5.Root.new(amchartRef.current);

  //   root.setThemes([am5themes_Animated.new(root)]);

  //   let chart = root.container.children.push(am5xy.XYChart.new(root, {
  //     panX: false,
  //     panY: false,
  //     wheelX: "panX",
  //     wheelY: "zoomX",
  //     paddingLeft: 0
  //   }));

  //   let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
  //     behavior: "zoomX"
  //   }));
  //   cursor.lineY.set("visible", false);

  //   let date = new Date();
  //   date.setFullYear(1900);
  //   date.setHours(0, 0, 0, 0);
  //   let value = 100;

  //   const generateData = () => {
  //     value = Math.round((Math.random() * 10 - 5) + value);
  //     am5.time.add(date, "day", 1);
  //     return {
  //       date: date.getTime(),
  //       value: null
  //     };
  //   }

  //   const generateDatas = (count) => {
  //     let data = [];
  //     for (let i = 0; i < count; ++i) {
  //       data.push(generateData());
  //     }
  //     return data;
  //   }

  //   let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
  //     groupData: true,
  //     maxDeviation: 0,
  //     baseInterval: {
  //       timeUnit: "day",
  //       count: 1
  //     },
  //     renderer: am5xy.AxisRendererX.new(root, {
  //       minorGridEnabled: true
  //     }),
  //     tooltip: am5.Tooltip.new(root, {})
  //   }));

  //   let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
  //     renderer: am5xy.AxisRendererY.new(root, {})
  //   }));

  //   let series = chart.series.push(am5xy.LineSeries.new(root, {
  //     name: "Series",
  //     xAxis: xAxis,
  //     yAxis: yAxis,
  //     valueYField: "value",
  //     valueXField: "date",
  //     tooltip: am5.Tooltip.new(root, {
  //       labelText: "{valueY}"
  //     })
  //   }));

  //   let scrollbar = am5xy.XYChartScrollbar.new(root, {
  //     orientation: "horizontal",
  //     height: 50
  //   });
  //   chart.set("scrollbarX", scrollbar);

  //   let sbxAxis = scrollbar.chart.xAxes.push(
  //     am5xy.DateAxis.new(root, {
  //       groupData: true,
  //       groupIntervals: [{ timeUnit: "month", count: 1 }],
  //       baseInterval: { timeUnit: "day", count: 1 },
  //       renderer: am5xy.AxisRendererX.new(root, {
  //         minorGridEnabled: true,
  //         opposite: false,
  //         strokeOpacity: 0
  //       })
  //     })
  //   );

  //   let sbyAxis = scrollbar.chart.yAxes.push(
  //     am5xy.ValueAxis.new(root, {
  //       renderer: am5xy.AxisRendererY.new(root, {})
  //     })
  //   );

  //   let sbseries = scrollbar.chart.series.push(
  //     am5xy.LineSeries.new(root, {
  //       xAxis: sbxAxis,
  //       yAxis: sbyAxis,
  //       valueYField: "value",
  //       valueXField: "date"
  //     })
  //   );

  //   let data = generateDatas(50000);
  //   series.data.setAll(data);
  //   sbseries.data.setAll(data);

  //   series.appear(1000);
  //   chart.appear(1000, 100);

  //   return () => {
  //     root.dispose();
  //   };
  // }, []);

  async function fetchCsvUrl() {
    try {
      const apiUrl = "http://localhost:4000/getFile";
      const res = await axios.get(apiUrl);
      setCsvUrl(res.data.url);
    } catch (error) {
      console.error("Error fetching CSV URL:", error);
    }
  }

  useEffect(() => {
    fetchCsvUrl();
  }, []);

  useEffect(() => {
    if (csvUrl) {
      let labelDate = new Date("2024-07-10");
      Papa.parse(csvUrl, {
        download: true,
        complete: (results) => {
          let data = [];
          let fileData = results.data[0];
          // data.push({ Label: "0", Value: 60 });
          data.push({
            index: 0,
            Value: 60,
            close: 60,
            high: 60,
            low: 60,
            open: 60,
            volume: 60,
            date: new Date(labelDate),
          });
          for (let i = 1; i < fileData.length; i++) {
            if (i % 100 === 0) {
              labelDate.setSeconds(labelDate.getSeconds() + 1);
            } 
            data.push({
              // Label: i.toString(),
              Value: parseFloat(fileData[i]) || 85,
              close: parseFloat(fileData[i]) || 85,
              date: new Date(labelDate),
              high: parseFloat(fileData[i]) || 85,
              index: i,
              low: parseFloat(fileData[i]) || 85,
              open: parseFloat(fileData[i]) || 85,
              volume: parseFloat(fileData[i]) || 85,
            });
          }
          // data.push({ Label: fileData.length.toString(), Value: 120 });
          data.push({
            index: fileData.length,
            Value: 120,
            close: 120,
            high: 120,
            low: 120,
            open: 120,
            volume: 120,
            date: new Date(labelDate),
          });
          console.log(data);
          setDataSource(data);
          setIsLoading(false);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    }
  }, [csvUrl]);

  useEffect(() => {
    const initData = () => {
      const today = new Date();
      const year = today.getFullYear();
      const dateMonth = today.getMonth();
      const dateEnd = new Date(year, dateMonth, 1);
      const dateStart = new Date(year - 1, dateMonth, 1);

      const stockData = StocksUtility.GetStocksBetween(dateStart, dateEnd);

      let minVal = Number.MAX_VALUE;
      let maxVal = Number.MIN_VALUE;
      let minIndex = 0;
      let maxIndex = 0;
      let idx = 0;
      let currentYear = 0;
      let currentQuarter = 0;

      for (const item of stockData) {
        if (minVal > item.close) {
          minVal = item.close;
          minIndex = idx;
        }
        if (maxVal < item.close) {
          maxVal = item.close;
          maxIndex = idx;
        }
        const itemYear = StocksUtility.GetYear(item.date);
        if (currentYear !== itemYear) {
          currentYear = itemYear;
          item.info = itemYear;
        }

        const itemQuarter = StocksUtility.GetQuarter(item.date);
        if (currentQuarter !== itemQuarter) {
          currentQuarter = itemQuarter;
          item.info = "Q" + itemQuarter;
        }

        item.index = idx;
        item.value = item.close;
        item.date = idx;
        item.label = idx;
        idx++;
      }

      stockData[100].info = "SPLIT";
      stockData[200].info = "SPLIT";
      stockData[250].info = "SPLIT";

      stockData[130].info = "DIV";
      stockData[270].info = "DIV";
      stockData[320].info = "DIV";

      stockData[minIndex].info = "MIN";
      stockData[maxIndex].info = "MAX";
      console.log(stockData);
      setData(stockData);
    };

    // initData();
  }, []);

  const handleCrosshairsVisible = (e) => {
    const visible = e.target.checked;
    const mode = visible ? "Both" : "None";
    setCrosshairsVisible(visible);
    setCrosshairsMode(mode);
  };

  const handleCalloutsVisible = (e) => {
    setCalloutsVisible(e.target.checked);
  };

  const handleFinalValuesVisible = (e) => {
    setFinalValuesVisible(e.target.checked);
  };

  const handleMarkersVisible = (e) => {
    const visible = e.target.checked;
    const types = visible ? [MarkerType.Automatic] : [MarkerType.None];
    setMarkersVisible(visible);
    setMarkerTypes(types);
  };

  const handleItemTooltipVisible = (e) => {
    const visible = e.target.checked;
    const type = visible ? "Item" : "None";
    setToolTipVisible(visible);
    setToolTipType(type);
  };

  return (
    <>
      {console.log(isLoading)}
      {/* <div id="chartdiv" ref={amchartRef} style={{ width: "100%", height: "500px" }}></div> */}
      {isLoading ? (
        <div className="loader-overlay">
          <CircularProgress size="5rem" />
        </div>
      ) : (
        <div className="container sample">
          {/* {dataSource && dataSource.length ? ( */}
          {true ? (
            <>
              {/* <div
                className="container h-full"
              >
                <IgrCategoryChart
                width="100%"
                height="100%"
                chartType="Line"
                toolTipType="Default"
                xAxisEnhancedIntervalPreferMoreCategoryLabels="false"
                shouldConsiderAutoRotationForInitialLabels="false"
                shouldAutoExpandMarginForInitialLabels="false"
                crosshairsDisplayMode="None"
                autoMarginAndAngleUpdateMode="None"
                markerTypes="None"
                dataSource={dataSource}
                defaultInteraction="DragPan"
              />
              </div> */}
              <div
                className="container h-full"
              >
                <IgrFinancialChart
                  ref={chartRef}
                  width="100%"
                  height="100%"
                  chartType="Line"
                  thickness={2}
                  // xAxisMode="Ordinal"
                  // yAxisMode="Numeric"
                  crosshairsSnapToData={false}
                  toolTipType="Default"
                  crosshairsDisplayMode="Both"
                  crosshairsAnnotationEnabled="true"
                  finalValueAnnotationsVisible="true"
                  autoMarginAndAngleUpdateMode="None"
                  markerTypes="None"
                  dataSource={dataSource}
                />
              </div>
              {/* <div className="container sample">
                <div className="options horizontal">
                  <label className="options-label">Annotations: </label>
                  <label className="options-item">
                    <input
                      type="checkbox"
                      checked={crosshairsVisible}
                      onChange={handleCrosshairsVisible}
                    />{" "}
                    Crosshair
                  </label>
                  <label className="options-item">
                    <input
                      type="checkbox"
                      checked={calloutsVisible}
                      onChange={handleCalloutsVisible}
                    />{" "}
                    Callouts
                  </label>
                  <label className="options-item">
                    <input
                      type="checkbox"
                      checked={toolTipVisible}
                      onChange={handleItemTooltipVisible}
                    />{" "}
                    Item Tooltip
                  </label>
                  <label className="options-item">
                    <input
                      type="checkbox"
                      checked={finalValuesVisible}
                      onChange={handleFinalValuesVisible}
                    />{" "}
                    Final Values
                  </label>
                  <label className="options-item">
                    <input
                      type="checkbox"
                      checked={markersVisible}
                      onChange={handleMarkersVisible}
                    />{" "}
                    Markers
                  </label>
                </div>

                <div
                  className="container"
                  style={{ height: "calc(100% - 65px)" }}
                >
                  <IgrFinancialChart
                    ref={chartRef}
                    width="100%"
                    height="100%"
                    chartType="Line"
                    thickness={2}
                    // xAxisMode="Ordinal"
                    // yAxisMode="Numeric"
                    dataSource={data}
                    // excludedProperties={["index", "info"]}
                    // calloutsVisible={calloutsVisible}
                    // calloutsXMemberPath="index"
                    // calloutsYMemberPath="value"
                    // calloutsLabelMemberPath="info"
                    // calloutsContentMemberPath="info"
                    crosshairsSnapToData={false}
                    toolTipType={toolTipType}
                    crosshairsDisplayMode={crosshairsMode}
                    crosshairsAnnotationEnabled={crosshairsVisible}
                    finalValueAnnotationsVisible={finalValuesVisible}
                    markerTypes={markerTypes}
                  />
                </div>
              </div> */}
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default Charts;

//////////////////
