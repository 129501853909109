import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { isTokenValid } from "../../../utils/sessionutils";
import { Tickets } from "./Cards/Tickets";
import { Add } from "@mui/icons-material";
import { SignUps } from "./Cards/SignUps";
import { OpenIssues } from "./Cards/OpenIssues";
import { AppUsage } from "./Cards/AppUsage";
import { OurSubscriptions } from "./Cards/Subscriptions";
import PageHeading from "../../../components/common/PageHeading";
// import { Link } from "react-router-dom";

const Home = () => {
  // const { handleAlert } = useAlert();
  useEffect(() => {
    isTokenValid();
  }, []);
  return (
    <Box m={2} pb={4}>
      <PageHeading heading={"Home"} icon={<Add />} iconText={"ADD"} />
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Tickets />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <SignUps />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <OpenIssues />
        </Grid>
        <Grid item lg={8} xs={12}>
          <AppUsage />
        </Grid>
        <Grid item lg={4} xs={12}>
          <OurSubscriptions />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
