import { Box, Typography } from "@mui/material";
import CustomButton from "./CustomButton";

const PageHeading = ({ heading, icon, iconText, iconCLick, ...rest }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Typography variant="h4">{heading}</Typography>
      {(icon || iconText) && (
        <CustomButton {...rest} onClick={iconCLick}>
          {icon} {iconText}
        </CustomButton>
      )}
    </Box>
  );
};
export default PageHeading;
