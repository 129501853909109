import {
  Avatar,
  Box,
  CardContent,
  CardHeader,
  Typography,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { isTokenValid } from "../../../utils/sessionutils";
import { patchRequest } from "../../../utils/request";
import { useSelector } from "react-redux";
import { CustomCard } from "../../../components/common/CustomCard";
import CustomInput from "../../../components/common/CustomInput";
import CustomButton from "../../../components/common/CustomButton";
import { InfoOutlined, PersonOutline } from "@mui/icons-material";
export const UserInfo = () => {
  const userData = useSelector((state) => state.user);
  const [changes, setChanges] = useState({});
  useEffect(() => {
    isTokenValid();
  }, []);

  const editUser = async () => {
    try {
      const response = await patchRequest("user", changes);
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e) => {
    setChanges({
      ...changes,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Stack spacing={4}>
      <Typography variant="h4">User Details</Typography>
      <CustomCard>
        <CardHeader
          title={"Profile"}
          titleTypographyProps={{ variant: "h6" }}
          sx={{ p: "32px 24px 16px" }}
          avatar={<PersonOutline />}
        />
        <CardContent sx={{ p: "16px 40px" }}>
          <Box display={"flex"}>
            <Avatar
              sx={{ width: 100, height: 100, marginBottom: 2 }}
              src="/broken-image.jpg"
            ></Avatar>
          </Box>

          <CustomInput
            fullWidth
            disabled
            variant="standard"
            value={userData?.user?.firstname ?? ""}
            id="firstname"
            name="firstname"
            labelText={"First name"}
          />
          <CustomInput
            fullWidth
            disabled
            variant="standard"
            value={userData?.user?.lastname ?? ""}
            id="lastname"
            name="lastname"
            labelText={"Last name"}
          />
          <CustomInput
            labelText={"Email"}
            fullWidth
            disabled
            sx={{ paddingLeft: 2 }}
            variant="standard"
            value={userData?.user?.email ?? ""}
          />

          <CustomInput
            labelText={"Phone"}
            fullWidth
            sx={{ paddingLeft: 2 }}
            variant="standard"
            onChange={handleChange}
            value={changes?.phone ?? userData?.user?.phone ?? ""}
            id="phone"
            name="phone"
          />
          <Box textAlign={"right"} m={2}>
            <CustomButton onClick={editUser}>SAVE</CustomButton>
          </Box>
        </CardContent>
      </CustomCard>
    </Stack>
  );
};
