import { ReactComponent as LogoIcon } from "../../assets/logo/healthblox_icon.svg";
import { ReactComponent as LogoText } from "../../assets/logo/healthblox_text.svg";
import { ReactComponent as LogoIconWhite } from "../../assets/logo/healthblox_icon_white.svg";
import { ReactComponent as LogoTextWhite } from "../../assets/logo/healthblox_text_white.svg";
import { Box } from "@mui/material";
export const HealthBloxLogo = ({ height, color, ...rest }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      {...rest}
    >
      {color === "white" ? (
        <>
          <LogoIconWhite
            style={{
              height: height,
              display: "block",
            }}
          />
          <LogoTextWhite style={{ height: height }} />
        </>
      ) : (
        <>
          <LogoIcon
            style={{
              height: height,
              display: "block",
            }}
          />
          <LogoText style={{ height: height }} />
        </>
      )}
      {/* <img src={logoIcon} style={{ height: 50 }} />
              <img src={logoText} style={{ height: 50 }} /> */}
      {/* <img src={logo} style={{ height: 50 }} />
              <Typography
                sx={{
                  textAlign: "center",
                  fontFamily: "Avenir-light",
                  // fontWeight: "bold",
                }}
                variant="h5"
              >
                HEALTHBLOX
              </Typography> */}
    </Box>
  );
};
