import { Card } from "@mui/material";

export const CustomCard = ({ children, sx, ...rest }) => {
  return (
    <Card
      sx={{
        ...sx,
        borderRadius: "20px ",
        borderStyle: "solid",
        borderColor: "grey.300",
        borderWidth: "1px ",
        boxShadow: "none",
      }}
      {...rest}
    >
      {children}
    </Card>
  );
};
