// ProgressTracker.js

import React from "react";
import "./ProgressTracker.scss";

const ProgressTracker = ({ steps, currentStep }) => {
  return (
    <div className="progress-tracker">
      <ul>
        {steps.map((step, index) => (
          <li>
            <div
              className={`step ${
                index === currentStep
                  ? "active"
                  : index < currentStep
                  ? "active"
                  : ""
              } ${index === 0 ? "first" : ""}`}
            >
              <p>{index + 1}</p>
            </div>
            <p className="label">{step}</p>
          </li>
          // <div
          //   key={index}
          //
          // >
          //   {step}
          // </div>
        ))}
      </ul>
    </div>
  );
};

export default ProgressTracker;
