import { Box, CircularProgress, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../utils/request";
import { useNavigate, useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
import { LoginLayout } from "../../layouts/LoginLayout";
import CustomButton from "../../components/common/CustomButton";

export const JoinOrg = () => {
  const [org, setOrg] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  const navigate = useNavigate();
  const getDetails = async () => {
    try {
      console.log(token);
      const response = await getRequest("organization/invite/details", {
        invite_token: token,
      });
      setOrg(response.data.org);
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  const joinOrg = async () => {
    setLoading(true);
    try {
      const response = await postRequest("user/organization/join", {
        token: token,
      });
      const orgId = response.data.org;
      console.log(orgId);
      await postRequest(
        "user/organization/switch",
        { id: orgId },
        {
          withCredentials: true,
        }
      );
      window.location.href = "/";
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    getDetails();
  }, []);
  // const login = useSelector((state) => state.islogin);
  return (
    <LoginLayout
      heading={"Join Organization"}
      subheading={
        org ? `You have been invited to join ${org}` : "Link expired or invalid"
      }
      social={false}
    >
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
        }}
      >
        <Stack spacing={1}>
          {org ? (
            <>
              <CustomButton disabled={loading} onClick={joinOrg}>
                {loading ? <CircularProgress size={25} /> : "Join"}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  navigate("/");
                }}
              >
                Exit
              </CustomButton>
            </>
          ) : (
            <>
              <CustomButton
                onClick={() => {
                  navigate("/");
                }}
              >
                Exit
              </CustomButton>
            </>
          )}
        </Stack>
      </Box>
    </LoginLayout>
  );
};
