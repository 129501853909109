import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { postRequest } from "../../../utils/request";
import { useSelector } from "react-redux";
import { isTokenValid } from "../../../utils/sessionutils";
import { useAlert } from "../../../services/context/AlertContext";

export const PhoneSetup = () => {
  const { handleAlert } = useAlert();
  const userData = useSelector((state) => state.user);
  const [phone, setPhone] = useState(userData?.user?.mfa_sms);
  const [verified, setVerified] = useState(userData?.user?.mfa_sms);
  const [otp, setOtp] = useState("");
  const [waiting, setWaiting] = useState(false);
  useEffect(() => {
    enable();
  }, []);
  const enable = async () => {
    setWaiting(true);
    // console.log(user.email);
    try {
      await postRequest("mfa/generateotp", {
        email: userData?.user?.email,
        type: "SMS",
      });
      setPhone(true);
      setWaiting(false);
    } catch (e) {
      console.log(e);
    }
  };
  const verifyOtp = async () => {
    try {
      const response = await postRequest("mfa/verifyotp", {
        token: otp,
        email: userData?.user?.email,
        type: "SMS",
      });
      console.log(response.data);
      setVerified(true);
      isTokenValid();
    } catch (err) {
      console.log(err);
      handleAlert("Could not verify, try again");
    }
  };
  return (
    <>
      <Box>
        {waiting && <CircularProgress size="25px" />}
        {phone &&
          (verified ? (
            <Alert severity="success">SMS authorization is enabled</Alert>
          ) : (
            <Box
              display={"flex"}
              flexDirection={"row "}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                sx={{ verticalAlign: "bottom" }}
              >
                <Typography marginX={"20px"}>Enter the otp:</Typography>
                <TextField
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  variant="standard"
                  sx={{ verticalAlign: "bottom" }}
                ></TextField>
              </Box>
              <Button
                sx={{ margin: "20px" }}
                onClick={() => {
                  verifyOtp();
                }}
              >
                Verify
              </Button>
            </Box>
          ))}
      </Box>
    </>
  );
};
