import React, { useState } from "react";
import "./popUp.scss";

import { useEffect, useRef, useContext } from "react";
import CareFlowContext from "../../../services/context/CareFlowContext";
import {
  getAllStandardForms,
  getAllForms,
  getAllJourney,
} from "../../../utils/form";
//  const allJourney = await getAllJourney()
//  const allForms = await getAllForms()
//  const allStandardForms = await getAllStandardForms()

const PopUpDrawer = (props) => {
  const ref = useRef(null);
  const { onClickOutside } = props;

  const [apps, setApps] = useState([]);

  const { appsList } = useContext(CareFlowContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    setApps(appsList)
  }, []);

  return (
    <div className="pop-up-draw w-full h-full" ref={ref}>
      <div className="flex flex-col">
        <div className="title-box">ALL APPS</div>
        <div className="flex flex-col app-dropdown">
          {apps.map((item) => {
            return (
              <div
                className="flex app-list"
                onClick={() => props.handleAppClick(item)}
              >
                <div className="app-icon">{item.Icon}</div>
                <div className="app-label">{item.label}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PopUpDrawer;
