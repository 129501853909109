import React, { useContext } from "react";
import "./popUp.scss";
import { RxCross2 } from "react-icons/rx";
import Add from "@mui/icons-material/Add";

import { useEffect, useRef, useState } from "react";
import CustomButton from "../../../components/common/CustomButton";
import { useReactFlow } from "reactflow";
import DropDown from "../../../components/common/dropdown";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "../../../components/common/TextField";
import SouthIcon from "@mui/icons-material/South";
import CareFlowContext from "../../../services/context/CareFlowContext";

const EdgePopUp = (props) => {
  const ref = useRef(null);
  const { onClickOutside } = props;
  const { sourceNodeID, targetNodeID, edgeID } = props;
  const { edgeData } = props;
  const { conditionData, setConditionData } = useContext(CareFlowContext);
  let activeConditionedge = {};
  for (let idx = 0; idx < conditionData?.length; idx++) {
    if (conditionData[idx]?.id === edgeID) {
      activeConditionedge = conditionData[idx]?.edge;
    }
  }

  const { setNodes, setEdges } = useReactFlow();
  const childVariables = [
    { label: "Var 1", value: "var1" },
    { label: "Var 2", value: "var2" },
    { label: "Var 3", value: "var3" },
    { label: "Var 4", value: "var4" },
    { label: "Var 5", value: "var5" },
  ];
  const operators = [
    { label: "Equals to (=)", value: "==" },
    { label: "Not equals to (!=)", value: "!=" },
    { label: "Greater than (>)", value: ">" },
    { label: "Less than (<)", value: "<" },
  ];

  const [conditionsList, setConditionsList] = useState(
    activeConditionedge?.transition || []
  );
  const [allNodes, setAllNodes] = useState([]);
  const [allEdges, setAllEdges] = useState([]);
  const [sourceNode, setSourceNode] = useState(null);
  const [targetNode, setTargetNode] = useState(null);

  function handleAddCondition() {
    let newConditionData = {
      variableKey: "",
      operation: null,
      operand: "",
    };
    setConditionsList((prevArray) => [...prevArray, newConditionData]);
  }

  function handleDeleteCondition(index) {
    let list = JSON.parse(JSON.stringify(conditionsList));
    list.splice(index, 1);
    setConditionsList(list);
  }
  function handleSaveConditions() {
    let allEdgesList = [...allEdges];
    let updatedActiveEdge = {};
    for (let i = 0; i < allEdgesList.length; i++) {
      if (allEdgesList[i].id === edgeID) updatedActiveEdge = allEdgesList[i];
    }
    updatedActiveEdge.transition = conditionsList;

    let tempConditionData = JSON.parse(JSON.stringify(conditionData));
    let indx = tempConditionData.findIndex((item) => item.id === edgeID);
    if (indx !== -1) {
      tempConditionData[indx].edge = updatedActiveEdge;
    } else tempConditionData.push({ id: edgeID, edge: updatedActiveEdge });
    setConditionData(tempConditionData);
    console.log("tempConditionData", tempConditionData);
    onClickOutside();
  }
  function updateConditionList(i, key, data, textFieldValue) {
    let list = [...conditionsList];
    if (key === "operand" || key === "variableKey") {
      list[i][key] = textFieldValue?.value || "";
    } else list[i][key] = data;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    setNodes((nds) => {
      setAllNodes(nds);
      setSourceNode(nds.filter((node) => node.id === sourceNodeID)[0]);
      setTargetNode(nds.filter((node) => node.id === targetNodeID)[0]);
      return nds;
    });
    setEdges((eds) => {
      setAllEdges(eds);
      return eds;
    });
  }, []);

  return (
    <div className="edge-pop-up-draw min-w-96" ref={ref}>
      <div className="flex flex-col">
        <div className="title-box flex justify-between">
          <div className="px-1 text-white">Set up a filter</div>
          <div className=" cursor-pointer text-xl" onClick={onClickOutside}>
            <RxCross2 />
          </div>
        </div>
        <div className="flex flex-col app-dropdown">
          <div className="flex justify-between">
            <div className="content-title">Configure transition</div>
            <div>
              <CustomButton
                size="small"
                variant={"text"}
                onClick={handleAddCondition}
              >
                <Add />
                Condition
              </CustomButton>
            </div>
          </div>
          <div>
            {conditionsList && conditionsList.length ? (
              <>
                {conditionsList.map((conditionData, index) => {
                  return (
                    <div className="condition-form">
                      <div className="flex justify-between">
                        <div className="content-title">If</div>
                        <div onClick={() => handleDeleteCondition(index)}>
                          <DeleteForeverIcon
                            sx={{ color: "#CF7447", cursor: "pointer" }}
                          />
                        </div>
                      </div>
                      {/* <DropDown
                        data={{
                          options: childVariables,
                          answer: conditionData.variableKey,
                        }}
                        placeholder={"Data Point"}
                        className="input-style"
                        setAnswer={(data) =>
                          updateConditionList(index, "variableKey", data)
                        }
                      /> */}

                      <TextField
                        className="w-full"
                        data={{ answer: { value: conditionData.variableKey } }}
                        setAnswer={(data, textFieldValue) =>
                          updateConditionList(
                            index,
                            "variableKey",
                            data,
                            textFieldValue
                          )
                        }
                      />
                      <div className="my-2">
                        <DropDown
                          data={{
                            options: operators,
                            answer: conditionData.operation,
                          }}
                          setAnswer={(data) =>
                            updateConditionList(index, "operation", data)
                          }
                          placeholder={"Select operation"}
                        />
                      </div>
                      <div className="content-title font-normal">Operand</div>

                      <TextField
                        className="w-full"
                        data={{ answer: { value: conditionData.operand } }}
                        setAnswer={(data, textFieldValue) =>
                          updateConditionList(
                            index,
                            "operand",
                            data,
                            textFieldValue
                          )
                        }
                      />
                    </div>
                  );
                })}
                {targetNode ? (
                  <>
                    <div className="text-center p-2">
                      <SouthIcon />
                    </div>
                    <div className="condition-form">
                      <div className="flex justify-between">
                        <div className="content-title">Go to</div>
                      </div>
                      <div className="my-2">
                        <DropDown
                          data={{
                            options: [],
                            answer: {
                              label: "Go To Step",
                              value: "Go To Step",
                            },
                          }}
                          placeholder={"Data Point"}
                          className="input-style my-2"
                          isDisabled={true}
                        />
                      </div>
                      <div className="my-2">
                        <DropDown
                          data={{
                            options: [],
                            answer: {
                              label: targetNode?.data?.label || "Next Action",
                              value: targetNode?.data?.label || "Next Action",
                            },
                          }}
                          placeholder={"Select operation"}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="flex justify-end bottom-box gap-2">
          <CustomButton
            variant={"outlined"}
            size="small"
            onClick={onClickOutside}
          >
            Cancel
          </CustomButton>
          <CustomButton size="small" onClick={handleSaveConditions}>
            Save
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default EdgePopUp;
