import React from "react";
import { ChecklistOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { CardLayout } from "./CardLayout";

export const Tickets = () => {
  return (
    <CardLayout icon={<ChecklistOutlined />} increase={"15"}>
      <Typography variant="overline">Tickets</Typography>
      <Typography variant="h4">31</Typography>
    </CardLayout>
  );
};
