import React from "react";
import { ReportProblemOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { CardLayout } from "./CardLayout";

export const OpenIssues = () => {
  return (
    <CardLayout icon={<ReportProblemOutlined />} increase={"12"}>
      <Typography variant="overline">Open Issues</Typography>
      <Typography variant="h4">21</Typography>
    </CardLayout>
  );
};
