import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { endSession } from "../../utils/sessionutils";
import { getRequest, patchRequest, postRequest } from "../../utils/request";
import { isWeakPassword } from "../../utils/password";
import { useAlert } from "../../services/context/AlertContext";
import CustomInput from "../../components/common/CustomInput";
import CustomButton, {
  CustomTextButton,
} from "../../components/common/CustomButton";

export const Details = ({
  activeStep,
  steps,
  handleNext,
  handleExit,
  handleBack,
}) => {
  const { handleAlert } = useAlert();
  const [form, setForm] = useState({});
  const [error, setError] = useState({});
  const [confirmpassword, setConfirmpassword] = useState(null);
  const [org, setOrg] = useState({});
  const [orgError, setOrgError] = useState(null);
  const [social, setSocial] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const getInfo = async () => {
      try {
        const response = await getRequest("user/me");
        if (response.data.user.onboarded) {
          navigate("/");
        }
        setForm({
          firstname: response.data.user.firstname,
          lastname: response.data.user.lastname,
          email: response.data.user.email,
        });
        setSocial(true);
      } catch (e) {
        endSession();
      }
    };
    getInfo();
  }, [navigate]);

  const verifyOrg = async (e) => {
    if (!e.target.value) {
      setOrgError("This is a required field");
      return false;
    }
    try {
      const response = await getRequest(`organization/name/${e.target.value}`);
      setOrgError(null);
      return true;
    } catch (error) {
      setOrgError(error.response?.data?.message ?? "something went wrong");
      return false;
    }
  };

  const Signup = async () => {
    const data = { ...form, org: org };
    try {
      let url;
      if (social) {
        console.log(data);
        url = `${process.env.REACT_APP_SERVER}/organization`;
      } else {
        url = `${process.env.REACT_APP_SERVER}/user/signup`;
      }
      const response = await postRequest(url, data);
      setOrgError(null);
      console.log(response);
      handleNext();
    } catch (error) {
      console.log(error);
      handleAlert(error.response?.message ?? "Something went wrong");
    }
  };

  const isError = () => {
    const length = social ? 3 : 4;
    if (
      activeStep === 0 &&
      Object.keys(form).length !== length &&
      !confirmpassword
    )
      return false;
    if (activeStep === 1) {
      if (Object.keys(org).length < 1) return false;
      if (orgError) return false;
    }

    return Object.values(error).every((value) => value === null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (social && activeStep === 0) {
      try {
        const response = await patchRequest("user", {
          firstname: form.firstname,
          lastname: form.lastname,
        });
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    }
    handleNext();
  };

  const handleConfirmpassword = (e) => {
    setConfirmpassword(e.target.value);
    setError({ ...error, confirmpassword: null });
    if (!e.target.value) {
      setError({ ...error, [e.target.name]: "This is a required field" });
      return;
    }
    if (form.password !== e.target.value) {
      setError({ ...error, [e.target.name]: "Password does not match" });
    }
  };

  const validfield = async (e) => {
    if (!e.target.value) {
      setError({ ...error, [e.target.name]: "This is a required field" });
      return false;
    }
    if (e.target.name === "password") {
      const invalidPassword = isWeakPassword(form.password);
      if (invalidPassword) {
        setError({ ...error, [e.target.name]: invalidPassword });
      }
      if (form.password === confirmpassword) {
        setError({ ...error, confirmpassword: null });
      }
    } else if (e.target.name === "email") {
      var emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (form.email.match(emailRegex)) {
        try {
          const response = await getRequest("user/check-email", {
            email: form.email,
          });
          console.log(response);
        } catch (error) {
          setError({
            ...error,
            email: error.response?.data?.message ?? "something went wrong",
          });
          console.log(error);
          return false;
        }
        return true;
      } else {
        setError({ ...error, email: "Invalid Email" });
        return false;
      }
    }
  };

  const handleForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setError({ ...error, [e.target.name]: null });
    console.log(form, error);
  };

  const handleOrg = (e) => {
    if (e.target.name === "name") {
      setOrg({
        ...org,
        [e.target.name]: e.target.value,
      });
      setOrgError(null);
      return false;
    } else {
      setOrg({
        ...org,
        address: {
          ...org.address,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const labelMappings = {
    firstname: "First Name",
    lastname: "Last Name",
    email: "Email",
    name: "Name",
    address: "Address",
    address_line1: "Address Line 1",
    address_line2: "Address Line 2",
    city: "City",
    state: "State/Province",
    country: "Country",
    zipcode: "Zipcode/Postal Code",
  };

  const convertLabel = (key) => labelMappings[key] || key;
  const renderObject = (obj) => {
    return Object.entries(obj)
      .filter(([key]) => key !== "password")
      .map(([key, value]) => {
        if (typeof value === "object") {
          return (
            <Box key={key}>
              <Typography variant="body2">
                <span style={{ color: "black" }}>{convertLabel(key)}:</span>{" "}
              </Typography>
              <Box style={{ marginLeft: "10px" }}>{renderObject(value)}</Box>
            </Box>
          );
        } else {
          return (
            <Box key={key}>
              <Typography variant="body2" key={key}>
                <span style={{ color: "black" }}>{convertLabel(key)}:</span>{" "}
                {value}
              </Typography>
            </Box>
          );
        }
      });
  };
  return (
    <>
      {activeStep === steps.length ? (
        <>
          <Box sx={{ mt: 3 }}>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {social
                ? "Organization Created"
                : "Check your inbox for verification email."}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <CustomButton onClick={handleExit}>Login</CustomButton>
          </Box>
        </>
      ) : (
        <>
          {activeStep === 0 && (
            <Box
              component="form"
              // noValidate
              onSubmit={handleSubmit}
            >
              <CustomInput
                autoComplete="given-name"
                name="firstname"
                value={form.firstname ? form.firstname : ""}
                onChange={handleForm}
                onBlur={validfield}
                error={!!error.firstname}
                helperText={error.firstname}
                required
                fullWidth
                id="firstname"
                labelText="First Name"
                autoFocus
              />
              <CustomInput
                required
                fullWidth
                value={form.lastname ? form.lastname : ""}
                onChange={handleForm}
                onBlur={validfield}
                error={!!error.lastname}
                helperText={error.lastname}
                id="lastname"
                labelText="Last Name"
                name="lastname"
                autoComplete="family-name"
              />

              <CustomInput
                disabled={social}
                required
                fullWidth
                value={form.email ? form.email : ""}
                onChange={handleForm}
                onBlur={validfield}
                error={!!error.email}
                helperText={error.email}
                id="email"
                labelText="Email Address"
                name="email"
                autoComplete="email"
              />

              {!social && (
                <>
                  <CustomInput
                    password={true}
                    required
                    fullWidth
                    value={form.password ? form.password : ""}
                    onChange={handleForm}
                    onBlur={validfield}
                    error={!!error.password}
                    helperText={error.password}
                    name="password"
                    labelText="Password"
                    id="password"
                    autoComplete="new-password"
                  />
                  {/* <PasswordInstructions password={form.password} /> */}

                  <CustomInput
                    password={true}
                    required
                    fullWidth
                    value={confirmpassword ? confirmpassword : ""}
                    onChange={handleConfirmpassword}
                    onBlur={validfield}
                    error={!!error.confirmpassword}
                    helperText={error.confirmpassword}
                    name="confirmpassword"
                    labelText="Confirm Password"
                    id="confirmpassword"
                    // autoComplete="new-password"
                  />
                </>
              )}
              {social && (
                <Grid mt={"10px"} container justifyContent="flex-end">
                  <Grid item>
                    {/* <Link to="/" variant="body2"> */}
                    <CustomTextButton
                      onClick={() => {
                        endSession();
                        navigate("/login");
                      }}
                    >
                      Logout
                    </CustomTextButton>
                    {/* </Link> */}
                  </Grid>
                </Grid>
              )}
            </Box>
          )}

          {activeStep === 1 && (
            <Box
              component="form"
              // noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <CustomInput
                autoComplete="company"
                name="name"
                value={org.name ? org.name : ""}
                onChange={handleOrg}
                onBlur={verifyOrg}
                error={!!orgError}
                helperText={orgError}
                required
                fullWidth
                id="name"
                labelText="Organization Name"
                autoFocus
              />
              <CustomInput
                fullWidth
                value={org.address?.address_line1 ?? ""}
                onChange={handleOrg}
                id="address_line1"
                labelText="Address Line 1"
                name="address_line1"
                autoComplete="address-line1"
                required
              />

              <CustomInput
                fullWidth
                value={org.address?.address_line2 ?? ""}
                onChange={handleOrg}
                id="address_line2"
                labelText="Address Line 2"
                name="address_line2"
                autoComplete="address-line2"
              />
              <CustomInput
                fullWidth
                value={org.address?.city ?? ""}
                onChange={handleOrg}
                id="city"
                labelText="City"
                name="city"
                autoComplete="address-level2"
                required
              />
              <CustomInput
                fullWidth
                value={org.address?.state ?? ""}
                onChange={handleOrg}
                id="state"
                labelText="State/Province"
                name="state"
                autoComplete="address-level1"
                required
              />
              <CustomInput
                fullWidth
                value={org.address?.country ?? ""}
                onChange={handleOrg}
                id="country"
                labelText="Country"
                name="country"
                autoComplete="country-name"
                required
              />
              <CustomInput
                fullWidth
                value={org.address?.zipcode ?? ""}
                onChange={handleOrg}
                id="zipcode"
                labelText="ZIP/ Postal Code"
                name="zipcode"
                autoComplete="postal-code"
                required
              />
            </Box>
          )}
          {activeStep === 2 && (
            <>
              <Typography marginY={"20px"} variant="body1">
                User Information:
              </Typography>

              {renderObject(form)}

              <Typography marginY={"20px"} variant="body1">
                Organization Information:
              </Typography>
              {renderObject(org)}
            </>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <CustomButton
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </CustomButton>
            <Box sx={{ flex: "1 1 auto" }} />

            {activeStep === steps.length - 1 ? (
              <CustomButton onClick={Signup}>Confirm </CustomButton>
            ) : (
              <CustomButton
                onClick={handleSubmit}
                type="submit"
                disabled={isError() ? false : true}
              >
                Next
              </CustomButton>
            )}
          </Box>
        </>
      )}
    </>
  );
};
