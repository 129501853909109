import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../utils/request";
import { isWeakPassword } from "../../utils/password";
import { useAlert } from "../../services/context/AlertContext";
import { LoginLayout } from "../../layouts/LoginLayout";
import CustomInput from "../../components/common/CustomInput";
import CustomButton from "../../components/common/CustomButton";

const SignUpWithInvite = () => {
  const { handleAlert } = useAlert();
  const { token } = useParams();
  const navigate = useNavigate();
  const [org, setOrg] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [form, setForm] = useState({ token: token });
  const [error, setError] = useState({});
  const handleForm = (e) => {
    setError({ ...error, [e.target.name]: null });
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const validfield = async (e) => {
    if (!e.target.value) {
      setError({ ...error, [e.target.name]: "This is a required field" });
      return false;
    }
    if (e.target.name === "password") {
      const invalidPassword = isWeakPassword(form.password);
      if (invalidPassword) {
        setError({ ...error, [e.target.name]: invalidPassword });
      }
      if (form.password === confirmPassword) {
        setError({ ...error, confirmPassword: null });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(form);
    // console.log(
    //   form.firstName,
    //   form.lastName,
    //   form.email,
    //   form.password
    // );
    if (form.password === confirmPassword) {
      try {
        const response = await postRequest("user/signup/invite", form);
        console.log(response);
        navigate("/login");
      } catch (e) {
        handleAlert(e.response?.data?.message ?? "Something went wrong");
        console.log(e);
      }
    } else {
      handleAlert("Passwords do not match!");
      return false;
    }
  };
  const getDetails = async () => {
    try {
      const response = await getRequest("organization/invite/details", {
        invite_token: token,
      });
      console.log(response.data);
      setEmail(response.data.email);
      setOrg(response.data.org);
    } catch (e) {
      console.log(e);
    }
  };
  const handleConfirmpassword = (e) => {
    setConfirmPassword(e.target.value);
    setError({ ...error, confirmpassword: null });
    if (!e.target.value) {
      setError({ ...error, [e.target.name]: "This is a required field" });
      return;
    }
    if (form.password !== e.target.value) {
      setError({ ...error, [e.target.name]: "Password does not match" });
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <LoginLayout
      heading={"Sign up"}
      subheading={`You have been invited to join ${org}`}
    >
      <Box
        component="form"
        // noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 3 }}
      >
        <CustomInput
          autoComplete="given-name"
          name="firstname"
          value={form.firstname ? form.firstname : ""}
          onChange={handleForm}
          onBlur={validfield}
          error={!!error.firstname}
          helperText={error.firstname}
          required
          fullWidth
          id="firstname"
          labelText="First Name"
          autoFocus
        />

        <CustomInput
          required
          fullWidth
          onChange={handleForm}
          onBlur={validfield}
          error={!!error.lastname}
          helperText={error.lastname}
          id="lastname"
          labelText="Last Name"
          name="lastname"
          autoComplete="family-name"
        />
        <CustomInput
          fullWidth
          disabled
          value={email}
          id="email"
          labelText="Email Address"
          name="email"
          autoComplete="email"
        />
        <CustomInput
          required
          fullWidth
          onChange={handleForm}
          onBlur={validfield}
          error={!!error.password}
          helperText={error.password}
          name="password"
          labelText="Password"
          type="password"
          id="password"
          autoComplete="new-password"
        />
        {/* <PasswordInstructions password={form.password} /> */}

        <CustomInput
          required
          fullWidth
          onChange={handleConfirmpassword}
          onBlur={validfield}
          error={!!error.confirmpassword}
          helperText={error.confirmpassword}
          name="confirmpassword"
          labelText="Confirm Password"
          type="password"
          id="confirmpassword"
        />
        <CustomButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </CustomButton>
      </Box>
    </LoginLayout>
  );
};

export default SignUpWithInvite;
