import { useState, useEffect } from "react";
import "./common.scss";

const SingleCheckBox = ({ data, setAnswer, formValidation, className }) => {
  const [selectedList, setSelectedList] = useState([]);
  const [validForm, setFormValidation] = useState(formValidation);

  const handleCheckboxClick = (value) => {
    if (selectedList?.includes(value)) {
      let optionsData = [...selectedList];
      optionsData.splice(optionsData.indexOf(value), 1);
      setSelectedList(optionsData);
      setAnswer && setAnswer(data, { value: optionsData });
      setFormValidation(true);
      // setAnswer({ question: data.label, answer: optionsData });
    } else {
      setSelectedList([...selectedList, value]);
      setAnswer && setAnswer(data, { value: [...selectedList, value] });
      setFormValidation(true);
      // setAnswer({ question: data.label, answer: [...selectedList, value] });
    }
  };

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedList(data.answer?.value);
    }
  }, [formValidation]);

  return (
    <div className={`w-full single-checkbox ${className}`}>
      <form>
        {/* <div className="radio-common my-2">
          <input
            type="checkbox"
            id={data.key}
            name={data.key}
            value={data.key}
            checked={selectedList.includes(data.key)}
            onChange={() => handleCheckboxClick(data.key)}
          />
          <label for={data.key} className="form-title pl-3">
            {data.label}
          </label>
        </div> */}
        <div className="checkbox-wrapper-4 radio-common">
          <input
            className="inp-cbx"
            name={data.key}
            value={data.key}
            checked={selectedList?.includes(data.key)}
            onChange={() => handleCheckboxClick(data.key)}
            id={data.key + "" + data.key}
            key={"index"}
            type="checkbox"
          />
          <label className="cbx" htmlFor={data.key + "" + data.key}>
            <span>
              <svg width="12px" height="10px">
                <use xlinkHref={`#check${data.key + "" + data.key}`}></use>
              </svg>
            </span>
            <span className="form-title">{data.label}</span>
          </label>
          <svg className="inline-svg">
            <symbol id={`check${data.key + "" + data.key}`} viewBox="0 0 12 10">
              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </symbol>
          </svg>
        </div>
      </form>
    </div>
  );
};
export default SingleCheckBox;
