import { Box, IconButton, Stack } from "@mui/material";
import googleLogo from "../assets/images/google_logo.svg";
import githubLogo from "../assets/images/github_logo.svg";
import { CustomButtonIcon } from "./common/CustomButton";
export const Social = ({ redirect }) => {
  const googleLogin = async () => {
    // await axios.get(
    //   `${process.env.REACT_APP_SERVER}/auth/google?returnTo=test`
    // );
    window.open(
      `${process.env.REACT_APP_SERVER}/user/login/auth/google?returnTo=${redirect}`,
      "_self"
    );
  };
  const githubLogin = async () => {
    window.open(
      `${process.env.REACT_APP_SERVER}/user/login/auth/github?returnTo=${redirect}`,
      "_self"
    );
  };
  return (
    <Stack spacing={2}>
      <CustomButtonIcon
        onClick={googleLogin}
        text="Continue with Google"
        logo={googleLogo}
      />
      <CustomButtonIcon
        onClick={githubLogin}
        text="Continue with Github"
        logo={githubLogo}
      />
    </Stack>
  );
};
