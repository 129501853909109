import React, { useEffect, useState, useContext } from "react";
import TextField from "../../../components/common/TextField";
import RadioSelector from "../../../components/common/RadioSelector";
import ContentRenderer from "../../../components/common/contentRenderer";
import CheckBoxSelector from "../../../components/common/CheckBoxSelector";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import FormContext from "../../../services/context/FormContext";
import FormRendererContext from "../../../services/context/FormRendererContext";
import { newFormSubmit } from "../../../utils/form";

const FormRenderer = ({
  formData,
  handleNextScreenPage,
  handleBackScreenPage,
  activeFormPageData,
}) => {
  const [formSchema, setFormSchema] = useState(null);
  const [formAnswer, setFormAnswer] = useState(null);
  const [activeFormPage, setActiveFormPage] = useState(null);
  const [activeFormPageId, setActiveFormPageId] = useState(null);

  const [formattedAnswer, setFormattedAnswer] = useState(null);

  const [formValid, setFormValidation] = useState(true);

  const { answerData, setAnswerData } = useContext(FormContext);
  const {
    journeyData,
    setJourneyData,
    activeScreenID,
    activeScreenPageID,
    completeJourneyAnswers,
    setCompleteJourneyAnswers,
  } = useContext(FormRendererContext);

  const fetchFormSchema = async (activeComponent) => {
    setFormSchema(activeComponent?.form?.form_schema?.formData);
    // setActiveFormPage(activeComponent?.form?.form_schema?.formData[0].content);
    // setActiveFormPageId(activeComponent?.form?.form_schema?.formData[0].id);
    setFormAnswer(activeComponent?.form);
  };

  const setAnswer = (question, answer) => {
    let tempAnswerList = JSON.parse(JSON.stringify(formattedAnswer?.answers));
    const questionKey = question?.key;

    let index = tempAnswerList.findIndex((item) => item.key === questionKey);
    if (index === -1) {
      tempAnswerList.push({
        key: questionKey,
        question: question?.label,
        answer,
      });
    } else {
      tempAnswerList[index].answer = answer;
    }
    setFormattedAnswer((prev) => ({ ...prev, answers: tempAnswerList }));

    let tempFormAnswer = JSON.parse(JSON.stringify(formAnswer));
    tempFormAnswer?.form_schema?.formData?.map((formPage) => {
      // formPage?.contents && formPage?.content?.components && formPage?.content?.components.map((item)=>{
      formPage?.content?.components.map((item) => {
        if (item?.key === questionKey) {
          item.answer = answer;
        }
      });
    });
    setFormAnswer(tempFormAnswer);
    setFormSchema(tempFormAnswer?.form_schema?.formData);

    let updatedAnswerData = JSON.parse(JSON.stringify(answerData));
    updatedAnswerData[questionKey] = answer;
    setAnswerData(updatedAnswerData);
    // console.log(tempFormAnswer);

    let updatedJourneyAnswers = JSON.parse(
      JSON.stringify(completeJourneyAnswers)
    );
    let indexJourneyAnswer = updatedJourneyAnswers.findIndex(
      (item) => item.key === questionKey
    );
    if (indexJourneyAnswer === -1) {
      updatedJourneyAnswers.push({
        key: questionKey,
        question: question?.label,
        answer,
      });
    } else {
      updatedJourneyAnswers[indexJourneyAnswer].answer = answer;
    }
    setCompleteJourneyAnswers(updatedJourneyAnswers);

    let updatedJourneyData = JSON.parse(JSON.stringify(journeyData));
    let screens = updatedJourneyData?.journey_schema?.screens;

    for (let i = 0; i < screens?.length; i++) {
      if (screens[i]?.id === activeScreenID) {
        let screenPages = screens[i]?.screenPages;
        for (let j = 0; j < screenPages?.length; j++) {
          if (screenPages[j]?.id === activeScreenPageID) {
            let form = screenPages[j]?.components?.form?.form_schema?.formData;
            for (let idx = 0; idx < form?.length; idx++) {
              if (form[idx]?.id === activeFormPageId) {
                let formContent = form[idx]?.content?.components;
                formContent?.map((ques) => {
                  if (ques?.key === questionKey) ques.answer = answer;
                });
              }
            }
          }
        }
      }
    }
    setJourneyData(updatedJourneyData);
  };

  const handleBack = () => {
    for (let i = 0; i < formSchema.length; i++) {
      if (formSchema[i].id === activeFormPageId) {
        if (i > 0) {
          setActiveFormPage(formSchema[i - 1].content);
          setActiveFormPageId(formSchema[i - 1].id);
        } else if (i === 0) {
          handleBackScreenPage();
        }
      }
    }
  };
  const handleNext = () => {
    let nextActionValid = true;
    for (let i = 0; i < formSchema.length; i++) {
      if (formSchema[i].id === activeFormPageId) {
        formSchema[i].content.components.map((questionData) => {
          if (questionData?.validate?.required && !questionData?.answer) {
            nextActionValid = false;
            setFormValidation(false);
          }
        });
      }
    }

    if (nextActionValid) {
      for (let i = 0; i < formSchema.length; i++) {
        if (formSchema[i].id === activeFormPageId) {
          if (i < formSchema.length - 1) {
            setActiveFormPage(formSchema[i + 1].content);
            setActiveFormPageId(formSchema[i + 1].id);
            setFormValidation(true);
          }
        }
      }
    }
  };
  const handleSubmit = () => {
    let nextActionValid = true;
    for (let i = 0; i < formSchema.length; i++) {
      if (formSchema[i].id === activeFormPageId) {
        formSchema[i].content.components.map((questionData) => {
          if (questionData?.validate?.required && !questionData?.answer) {
            nextActionValid = false;
            setFormValidation(false);
          }
        });
      }
    }
    if (nextActionValid) {
      console.log(formAnswer);
      console.log(formattedAnswer);
      newFormSubmit(formattedAnswer, formData?.form?.org);
      handleNextScreenPage();
    }
  };

  const checkForQuestionToShow = (ques) => {
    let show = true;
    if (ques?.conditional && ques.conditional?.eq && ques.conditional?.when) {
      show = !ques.conditional?.show;
      let journeyAnswerIndex = completeJourneyAnswers.findIndex(
        (item) => item.key === ques.conditional.when
      );
      let answer = completeJourneyAnswers[journeyAnswerIndex]?.answer?.value;
      if (
        (typeof answer === "string" && answer === ques.conditional?.eq) ||
        (Array.isArray(answer) && answer.includes(ques.conditional?.eq))
      ) {
        show = ques.conditional?.show || false;
      }
    }
    return show;
  };

  useEffect(() => {
    if (formData) {
      setFormattedAnswer({
        type: formData?.type,
        formId: formData.formId,
        answers: [],
      });
      fetchFormSchema(formData);
    }
  }, [formData]);

  useEffect(() => {
    setActiveFormPage(activeFormPageData?.content);
    setActiveFormPageId(activeFormPageData?.id);
  }, [activeFormPageData]);
  return (
    <div>
      <div>
        <div className="">
          {activeFormPage &&
            activeFormPage?.components &&
            activeFormPage?.components?.map((item) => {
              return (
                <>
                  {checkForQuestionToShow(item) && item.type === "textfield" ? (
                    <div className="flex justify-center">
                      <TextField data={item} setAnswer={setAnswer} />
                    </div>
                  ) : checkForQuestionToShow(item) && item.type === "radio" ? (
                    <div className="flex justify-center">
                      <RadioSelector
                        data={item}
                        setAnswer={setAnswer}
                        formValidation={formValid}
                      />
                    </div>
                  ) : checkForQuestionToShow(item) &&
                    item.type === "content" ? (
                    <div className="flex">
                      <ContentRenderer data={item} />
                    </div>
                  ) : checkForQuestionToShow(item) &&
                    item.type === "selectboxes" ? (
                    <div className="flex justify-center">
                      <CheckBoxSelector data={item} setAnswer={setAnswer} />
                    </div>
                  ) : (
                    // ) : checkForQuestionToShow(item) && item.type === "payment-widget" ? (
                    //   <div className="flex justify-center">
                    //     <StripeCheckout
                    //       org_id={item?.org_id}
                    //       amount={item?.amount || 0}
                    //     />
                    //   </div>
                    <></>
                    //   <div className="px-52">
                    //     <FormRenderer
                    //       form={{
                    //         display: "form",
                    //         components: [JSON.parse(JSON.stringify(item))],
                    //       }}
                    //     />
                    //   </div>
                  )}
                </>
              );
            })}
        </div>
        <div className="flex justify-between pt-3 pb-36 w-96 m-auto">
          <button
            onClick={handleBack}
            className="flex text-xl rounded hover:bg-indigo-400 shadow p-2 mustard_bg text-white px-3 cursor-pointer mr-24"
          >
            <FiChevronsLeft size={28} /> Back
          </button>
          {formSchema &&
          formSchema[formSchema?.length - 1].id === activeFormPageId ? (
            <div
              onClick={handleSubmit}
              className="flex text-xl hover:bg-indigo-400 rounded shadow p-2 mustard_bg text-white px-3 cursor-pointer ml-24"
            >
              Submit
            </div>
          ) : (
            <div
              onClick={handleNext}
              className="flex text-xl hover:bg-indigo-400 rounded shadow p-2 mustard_bg text-white px-3 cursor-pointer ml-24"
            >
              Next <FiChevronsRight size={28} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormRenderer;
