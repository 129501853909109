import React, { useState, useContext, useEffect } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  NodeToolbar,
  getSmoothStepPath,
  getStraightPath,
  useReactFlow,
  getBezierPath,
} from "reactflow";
import CareFlowContext from "../../../services/context/CareFlowContext";

import "./buttonedge.css";
import { TbFilterEdit } from "react-icons/tb";
import EdgePopUp from "../edgePopup";

export default function CustomEdge(edge) {
  const { conditionData } = useContext(CareFlowContext);
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    source,
    target,
  } = edge;
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [openDrawer, setOpenDrawer] = useState(false);
  const [edgeWithFilter, setEdgeWithFilter] = useState(false);

  const onEdgeClick = (evt) => {
    evt.stopPropagation();
    setOpenDrawer(true);
  };

  useEffect(() => {
    setEdgeWithFilter(false)
    for (let i = 0; i < conditionData?.length; i++) {
      if (
        conditionData[i]?.edge?.id === edge?.id &&
        conditionData[i]?.edge?.transition &&
        conditionData[i].edge.transition?.length
      ) {
        setEdgeWithFilter(true);
      }
    }

  }, [conditionData]);

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 14,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <div
            className={
              edgeWithFilter ? "edgebutton edgebutton-selected" : "edgebutton"
            }
            // onClick={onEdgeClick}
          >
            <TbFilterEdit
              size={edgeWithFilter ? "30px" : "10px"}
              color={edgeWithFilter ? "green" : ""}
            />
          </div>
        </div>
        {/* {openDrawer && false ? (
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${
                labelX + 150
              }px,${labelY}px)`,
              pointerEvents: "all",
              zIndex: 1099,
            }}
          >
            <EdgePopUp
              onClickOutside={() => {
                setOpenDrawer(false);
              }}
              sourceNodeID={source}
              targetNodeID={target}
              edgeID={id}
              edgeData={edge}
              // handleAppClick={handleAppClick}
            />
          </div>
        ) : (
          <></>
        )} */}
      </EdgeLabelRenderer>
    </>
  );
}
