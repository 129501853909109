import React, { useEffect, useState } from "react";
import "./FormSideBar.css";
import { FiMinus, FiPlus } from "react-icons/fi";
import FormCard from "../formCard";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { getAllForms, getAllStandardForms } from "../../../utils/form";
import Widget from "../widgets";
import { useSelector } from "react-redux";
import SchedulerWidget from "../scheduler-widget";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CustomCard } from "../../../components/common/CustomCard";
import { Link } from "react-router-dom";

const FormSideBar = () => {
  const [userForms, setUserForms] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const [standardForms, setStandardForms] = useState([]);
  const [showStandardLoader, setShowStandardLoader] = useState(true);

  const [widgets, setWidgets] = useState(null);

  const [showForm, setShowForm] = useState("standard");
  const user = useSelector((state) => state?.user);

  async function getAllFormsData() {
    const res = await getAllForms();
    setUserForms(res?.data?.forms);
    setShowLoader(false);
  }
  async function getAllStandardFormsData() {
    const res = await getAllStandardForms();
    setStandardForms(res?.data?.forms);
    setShowStandardLoader(false);
  }
  useEffect(() => {
    getAllFormsData();
    getAllStandardFormsData();
    setWidgets([
      {
        type: "payment-widget",
        label: "Payment Widget",
        org_id: user?.org?._id,
      },
      {
        type: "scheduler-widget",
        label: "Schedule an Appointment",
      },
    ]);
  }, []);
  return (
    <div className="form-sidebar">
      <div>
        <CustomCard>
          <div className="custom-width">
            <div className=" py-2 text-center text-xl font-semibold">
              <div className="flex justify-between px-3 items-center">
                <div>HBx Standard Forms</div>
                {showForm && showForm === "standard" ? (
                  <FiMinus
                    className="font-bold cursor-pointer"
                    onClick={() => setShowForm(null)}
                  />
                ) : (
                  <FiPlus
                    className="font-bold cursor-pointer"
                    onClick={() => setShowForm("standard")}
                  />
                )}
              </div>
            </div>
            {showForm && showForm === "standard" ? (
              showStandardLoader ? (
                <div className="font-semibold text-2xl my-2 text-center">
                  <Stack spacing={2} className="px-2">
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={150}
                      className="rounded"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={150}
                      className="rounded"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={150}
                      className="rounded"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={150}
                      className="rounded"
                    />
                  </Stack>
                </div>
              ) : (
                <div className="flex flex-col p-1 h-full custom-height overflow-y-scroll">
                  {standardForms &&
                    standardForms.map((form, idx) => {
                      return (
                        <FormCard form={form} key={idx} type="standard-form" />
                      );
                    })}
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </CustomCard>
        <CustomCard className="mt-2">
          <div className="custom-width">
            <div className="py-2 text-center text-xl font-semibold">
              <div className="flex justify-between px-3 items-center">
                <div>HBx Widgets </div>
                {showForm && showForm === "widgets" ? (
                  <FiMinus
                    className="font-bold cursor-pointer"
                    onClick={() => setShowForm(null)}
                  />
                ) : (
                  <FiPlus
                    className="font-bold cursor-pointer"
                    onClick={() => setShowForm("widgets")}
                  />
                )}
              </div>
            </div>
            {showForm && showForm === "widgets" ? (
              <div className="flex flex-col p-1 h-full custom-height overflow-y-scroll">
                {widgets &&
                  widgets.map((widget, idx) => {
                    return (
                      <>
                        {widget?.type === "payment-widget" && (
                          <Widget widget={widget} key={idx} />
                        )}
                        {widget?.type === "scheduler-widget" && (
                          <SchedulerWidget widget={widget} key={idx} />
                        )}
                      </>
                    );
                  })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </CustomCard>
        <CustomCard className="mt-2">
          <div className="custom-width">
            <div className="py-2 text-center text-xl font-semibold">
              <div className="flex justify-between px-3 items-center">
                <div>User Forms</div>
                {showForm && showForm === "user" ? (
                  <FiMinus
                    className="font-bold cursor-pointer"
                    onClick={() => setShowForm(null)}
                  />
                ) : (
                  <FiPlus
                    className="font-bold cursor-pointer"
                    onClick={() => setShowForm("user")}
                  />
                )}
              </div>
            </div>
            {showForm && showForm === "user" ? (
              showLoader ? (
                <div className="font-semibold text-2xl my-2 text-center">
                  <Stack spacing={2} className="px-2">
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={150}
                      className="rounded"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={150}
                      className="rounded"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={150}
                      className="rounded"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={250}
                      height={150}
                      className="rounded"
                    />
                  </Stack>
                </div>
              ) : (
                <div className="flex flex-col p-1 h-full custom-height overflow-y-scroll">
                  <div className="flex justify-end px-1">
                    <Link className="cursor-pointer" to={"/edit-form"}>
                      <AddCircleIcon className="bg-white mustard_text" />
                    </Link>
                  </div>

                  {userForms &&
                    userForms.map((form, idx) => {
                      return (
                        <FormCard form={form} key={idx} type="user-form" />
                      );
                    })}
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </CustomCard>
      </div>
    </div>
  );
};
export default FormSideBar;
