import React, { useEffect, useRef, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TextField,
  IconButton,
  TablePagination,
  Stack,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import "./table.css";
import { Add, Close, Delete, Edit, Save, Upload } from "@mui/icons-material";
import CustomButton from "../../components/common/CustomButton";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../utils/request";
import { useParams } from "react-router-dom";
import { useAlert } from "../../services/context/AlertContext";
import CustomInput, {
  CustomInputSelect,
} from "../../components/common/CustomInput";
import axios from "axios";

const CustomTable = ({ space, type, fields, importDatafromCSV }) => {
  let columns = fields.map((field) => ({
    id: field.key,
    label: field.display_name,
    type: field.type,
    align: "center",
  }));
  columns = [
    ...columns,
    {
      id: "created_at",
      label: "Created At",
      // type: "actions",
      align: "center",
    },
    {
      id: "modified_at",
      label: "Modified At",
      // type: "actions",
      align: "center",
    },
    {
      id: "modified_by",
      label: "Modified By",
      // type: "actions",
      align: "center",
    },
    {
      id: "Actions",
      label: "Actions",
      type: "actions",
      align: "center",
    },
  ];
  const { spaceID } = useParams();
  const actions = (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Edit sx={{ cursor: "pointer" }} />
      <Delete sx={{ cursor: "pointer" }} />
    </Stack>
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const processEntry = async (entry, send = false) => {
    if (send) {
      delete entry.Actions;
      delete entry._id;
      delete entry.modified_at;
      delete entry.modified_by;
      delete entry.created_at;
    } else {
      delete entry.deleted_at;
      entry.Actions = actions;
      Object.keys(entry).forEach((key) => {
        if (entry[key] === null) {
          entry[key] = "";
        }
      });
    }
    return entry;
  };
  const fetchData = async () => {
    try {
      const response = await getRequest(
        `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customtable/${
          spaceID ?? space?._id
        }`
      );
      let modifiedEntries = response.data.customTables;
      modifiedEntries.forEach((entry) => {
        entry = processEntry(entry);
      });
      setData(modifiedEntries);
    } catch (e) {
      // console.log(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (spaceID || space?._id) fetchData();
    else {
      setLoading(false);
    }
  }, []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleAddRow = async () => {
    let keys = [...fields.map((field) => field.key), "Actions"];
    const blankEntry = keys.reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});
    const response = await postRequest(
      `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customtable/${space._id}`,
      { data: blankEntry }
    );
    fetchData();
    // updatedData[rowIndex] = newEntry;
    // setData(updatedData);

    // const newData = [blankEntry, ...data];
    // setData(newData);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowChange = async (newValue, rowIndex) => {
    let updatedData = [...data];

    if (newValue._id) {
      updatedData[rowIndex] = newValue;
      try {
        const entry = await processEntry({ ...newValue }, true);
        delete entry.Actions;
        const response = await patchRequest(
          `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customtable/${space?._id}/${newValue._id}`,
          { data: entry }
        );
        setData(updatedData);
      } catch (e) {
        // console.log(e);
      }
    } else {
      try {
        const entry = await processEntry({ ...newValue }, true);
        delete entry.Actions;
        const response = await postRequest(
          `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customtable/${space._id}`,
          { data: entry }
        );
        const newEntry = { _id: response.data.entry.insertedId, ...newValue };
        updatedData[rowIndex] = newEntry;
        setData(updatedData);
      } catch (e) {
        // console.log(e);
      }
      setData(updatedData);
    }
  };

  const handleRowDelete = async (id) => {
    // const updatedData = data.filter((_, index) => index !== rowIndex);
    try {
      const response = await deleteRequest(
        `${process.env.REACT_APP_FOUNTAIN_HEAD_URL}/customtable/${space?._id}/${id}`
      );
      fetchData();
    } catch (e) {
      // console.log(e);
    }
  };
  const fileInputRef = useRef(null);
  const openFile = () => {
    fileInputRef.current.click();
  };
  const { handleLoader } = useAlert();
  return (
    <Stack m={2} spacing={4} p={2}>
      <Box
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">
          {type === "display" ? space?.common_name : "Edit Entries"}
        </Typography>
        <Stack direction={"row"} spacing={1}>
          <CustomButton onClick={openFile}>
            <Upload /> Import From CSV
          </CustomButton>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".csv"
            onChange={async (event) => {
              setLoading(true);
              await importDatafromCSV(event);
              setLoading(false);
            }}
          />
          <CustomButton onClick={() => handleLoader(handleAddRow)}>
            <Add /> Add Entry
          </CustomButton>
        </Stack>
      </Box>
      {loading ? (
        <Box
          width={"100%"}
          height={500}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress sx={{ color: "#CF7447" }} />
        </Box>
      ) : (
        <Paper
          sx={{
            // maxWidth: "90vw",
            overflow: "hidden",
            borderRadius: "16px",
            border: "1px",
            borderColor: "inherit",
            border: "solid",
            boxShadow: "none",
          }}
          maxHeight={type === "display" ? 600 : 400}
        >
          {data.length > 0 ? (
            <TableContainer
              max
              sx={{
                maxHeight: 500,
                height: type === "display" ? 440 : 350,
                // scrollbarWidth: "none",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="center"
                        style={{
                          minWidth: column.id === "Actions" ? 150 : 200,
                          background: "#f9fafb",
                          color: "#667085",
                          fontWeight: "600",
                        }}
                        className={
                          column.id === "Actions" ? "sticky-actions-header" : ""
                        }
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <MyTableRow
                        key={row._id}
                        row={row}
                        index={index}
                        handleRowChange={handleRowChange}
                        handleRowDelete={handleRowDelete}
                        columns={columns}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <div>
                <div className="text-center font-semibold text-lg py-3 shadow my-5 mx-96 rounded">
                  No Data Found
                </div>
              </div>
            </>
          )}
          <TablePagination
            rowsPerPageOptions={[50, 20, 10, 5]}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component={"div"}
            className="m-auto"
            // sx={{
            //   display: "flex",
            //   justifyContent: "flex-end",
            //   alignItems: "",
            //   alignContent: "center",
            //   padding: "10px",
            //   text: "center",
            // }}
          />
        </Paper>
      )}
    </Stack>
  );
};

export default CustomTable;

const MyTableRow = ({
  row,
  index,
  handleRowChange,
  handleRowDelete,
  columns,
}) => {
  const { handleLoader, handleAlert } = useAlert();
  const [rowData, setRowData] = useState({ ...row });
  const [edit, setEdit] = useState(false);
  const handleEditCell = (newValue, columnName) => {
    const updatedRow = { ...rowData };
    updatedRow[columnName] = newValue;
    setRowData(updatedRow);
  };
  const handleFillData = async (value, columnName) => {
    if (columnName.trim() === "athena_patient_id") {
      const updatedRow = { ...rowData };
      updatedRow["month"] = updatedRow["month"] ?? "May";
      updatedRow["year"] = updatedRow["year"] ?? "2024";
      updatedRow["prescribed_date"] =
        updatedRow["prescribed_date"] ?? "10/05/24";
      try {
        const body = {
          resource: "Patient",
          responseSystem: "HBx",
          requestSystem: "HBx",
          method: "GET",
          content: {
            patientId: value.trim(),
          },
        };
        const headers = {
          headers: {
            "x-auth-token": process.env.REACT_APP_SERVICE_TOKEN,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.post(
          "https://fountainhead-sandbox.healthblox.care/api/v1/resources/66119decee46f5682d682f88",
          body,
          headers
        );

        if (columns.find(({ id }) => id === "first_name"))
          updatedRow["first_name"] = response.data?.HBx?.firstname;
        if (columns.find(({ id }) => id === "last_name"))
          updatedRow["last_name"] = response.data?.HBx?.lastname;
        if (columns.find(({ id }) => id === "dob"))
          updatedRow["dob"] = response.data?.HBx?.dob;
        if (columns.find(({ id }) => id === "address"))
          updatedRow["address"] =
            response.data?.HBx?.address1 +
            ", " +
            response.data?.HBx?.city +
            ", " +
            response.data?.HBx?.state +
            ", " +
            response.data?.HBx?.countrycode +
            ", " +
            response.data?.HBx?.zip;
        if (columns.find(({ id }) => id === "insurance"))
          updatedRow["insurance"] = "Self";
      } catch (e) {
        if (e.data?.message)
          handleAlert(e.data.message ?? "Something went wrong");
      }
      setRowData(updatedRow);
    }
    if (columnName.trim() === "tracking_number") {
      const updatedRow = { ...rowData };
      if (columns.find(({ id }) => id === "shipment_status"))
        updatedRow["shipment_status"] = "In Transit";
      if (columns.find(({ id }) => id === "shipment_date"))
        updatedRow["shipment_date"] = "05/10/24";
      setRowData(updatedRow);
    }
  };
  return (
    <TableRow key={row._id}>
      {columns.map((column) => (
        <TableCell
          key={column.id}
          align="center"
          className={column.label === "Actions" ? "sticky-actions" : ""}
        >
          {column.label === "Actions" ? (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {edit ? (
                <Save
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    handleRowChange(rowData, index);
                    setEdit(false);
                  }}
                />
              ) : (
                <Edit
                  sx={{ cursor: "pointer" }}
                  onClick={() => setEdit(true)}
                />
              )}
              {edit ? (
                <Close
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setEdit(false);
                    setRowData(row);
                  }}
                />
              ) : (
                <Delete
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleLoader(() => handleRowDelete(row._id))}
                />
              )}
            </Stack>
          ) : edit ? (
            // ? (
            //   fields.find((field) => field.key === column.id).type === "enum" ? (
            //     <CustomInputSelect
            //     // menuItems={fields.find((field) => field.key === column.id).enum}
            //     // value={fieldName?.display_name}
            //     // onChange={(e) => {
            //     //   handleSelectColumn(index, e.target.value);
            //     // }}
            //     />
            //   ) :
            <CustomInput
              variant="outlined"
              size="small"
              fullWidth
              disabled={
                column.id === "created_at" ||
                column.id === "modified_at" ||
                column.id === "modified_by"
                  ? true
                  : false
              }
              value={rowData[column.id] ?? ""}
              onChange={(e) => {
                handleEditCell(e.target.value, column.id);
              }}
              onBlur={(event) => {
                if (
                  column.id === "athena_patient_id" ||
                  column.id === "tracking_number"
                )
                  handleLoader(() =>
                    handleFillData(event.target.value, column.id)
                  );
              }}
            />
          ) : (
            <Typography
              onClick={() => {
                setEdit(true);
              }}
            >
              {row[column.id] ?? ""}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};
