import React from "react";
import { Stack, Typography, Avatar, CardContent, Divider } from "@mui/material";
import { TrendingDown, TrendingUp } from "@mui/icons-material";
import { CustomCard } from "../../../../components/common/CustomCard";

export const CardLayout = ({ children, icon, increase, decrease }) => {
  return (
    <CustomCard sx={{ height: "200px" }}>
      <Stack direction="column" spacing={0}>
        <CardContent sx={{ height: "120px", overflowY: "auto" }}>
          <Stack spacing={3}>
            <Stack direction="row" sx={{ alignItems: "flex-end" }} spacing={3}>
              <Avatar
                sx={{
                  backgroundColor: "#FCFCF9", //"#231751",
                  height: "56px",
                  width: "56px",
                  borderRadius: "50%",
                  color: "#231751",
                }}
              >
                {icon}
              </Avatar>
              <Stack spacing={1}>{children}</Stack>
            </Stack>
          </Stack>
        </CardContent>
        {/* </Card> */}
        <Divider
          sx={{
            borderStyle: "solid",
            borderColor: "grey.300",
            borderWidth: "1px 0 0 0",
          }}
        />
        {/* <Card
        sx={{
          borderRadius: "0 0 12px 12px", // curved bottom borders
          // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          borderStyle: "solid",
          borderColor: "grey.300",
          borderWidth: "0px 1px 1px 1px",
        }}
      > */}
        <CardContent>
          {increase && <TrendingUp style={{ color: "#15b79f" }} />}
          {decrease && <TrendingDown style={{ color: "#F04438" }} />}
          <Typography variant="caption">
            {" "}
            <span style={{ color: increase ? "#15b79f" : "#F04438" }}>
              {`${increase ?? decrease}%`}
            </span>{" "}
            {increase ? "increase" : "decrease"} vs last month
          </Typography>
        </CardContent>
      </Stack>
    </CustomCard>
  );
};
