import * as React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useState } from "react";
import { DashboardList } from "./DashboardList";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogoWhite } from "../assets/logo/healthblox_icon_white.svg";
import { HealthBloxLogo } from "./common/LogoText";
import CustomDrawer from "./CustomDrawer";

import MuiAppBar from "@mui/material/AppBar";
import { Box, TextField, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { SettingsMenu } from "./Settings";
import {
  NotificationsOutlined,
  PersonOutlined,
  Search,
} from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  colour: "black",
  boxShadow: "none",
  borderBottom: "1px solid #e8e8e8",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    backgroundColor: "white",
    colour: "black",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
export const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  // color: "white",
  justifyContent: open ? "space-between" : "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function AppBarDrawer({ mode }) {
  const [open, setOpen] = useState(window.innerWidth <= 600 ? false : true);
  const handleDrawer = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/");
  let currentPage = path[path.length - 1].replace("_", " ");
  if (!currentPage && path.length > 1) {
    currentPage = path[path.length - 2].replace("_", " ");
  }
  // .replace("/", "").replace("_", " ");
  const [searchOpen, setSearchOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {window.innerWidth <= 600 && (
            <IconButton
              // color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box
            display="flex"
            marginLeft={!open && !window.innerWidth <= 600 ? "60px" : 0}
            justifyContent={"space-between"}
            width={"100%"}
            alignItems={"center"}
          >
            {searchOpen ? (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                width="100%"
              >
                <TextField
                  id="standard-basic"
                  variant="standard"
                  placeholder="Search..."
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setSearchOpen((search) => !search)}
                        edge="end"
                        aria-label="search"
                      >
                        <Search />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            ) : (
              <IconButton
                onClick={() => setSearchOpen((search) => !search)}
                aria-label="search"
              >
                <Search />
              </IconButton>
            )}
            {/* <Typography variant="h6" noWrap component="div">
          {currentPage ? currentPage.toUpperCase() : "HOME"}
        </Typography> */}

            <Box display="flex">
              <IconButton onClick={() => navigate("/")}>
                <HomeIcon />
              </IconButton>
              <IconButton
                // color="inherit"
                onClick={() => navigate("/user_settings")}
              >
                <SettingsIcon />
              </IconButton>
              <IconButton>
                <NotificationsOutlined />
              </IconButton>
              <IconButton onClick={handleClick}>
                <PersonOutlined />
              </IconButton>
              <SettingsMenu anchorEl={anchorEl} handleClose={handleClose} />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <CustomDrawer open={open}>
        <DrawerHeader
          open={open}
          sx={{
            justifyContent: open ? "space-between" : "center",
          }}
        >
          {open && (
            <>
              <Box onClick={() => navigate("/")}>
                <HealthBloxLogo height={"30px"} color={"white"} />
              </Box>
            </>
          )}

          <IconButton
            onClick={handleDrawer}
            sx={{
              color: "white",
              p: open ? "auto" : "0px",
              alignItems: "center",
            }}
          >
            {open ? (
              <ChevronLeftIcon />
            ) : (
              <LogoWhite style={{ height: "30px" }} />
            )}
          </IconButton>
        </DrawerHeader>

        <DashboardList open={open} />
      </CustomDrawer>
    </>
  );
}
