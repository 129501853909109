import React, { useState, useEffect, useRef } from "react";
import { WebglPlot, WebglLine, ColorRGBA } from "webgl-plot";

const Plot3 = () => {
  const canvasPlotRef = useRef(null);
  const canvasXAxisRef = useRef(null);
  const canvasYAxisRef = useRef(null);

  const [zoom, setZoom] = useState(false);
  const [drag, setDrag] = useState(false);

  const [cursorDownX, setCursorDownX] = useState(0);

  const [dragInitialX, setDragInitialX] = useState(0);
  const [dragOffsetOldX, setDragOffsetOldX] = useState(0);
  const [dragInitialY, setDragInitialY] = useState(0);
  const [dragOffsetOldY, setDragOffsetOldY] = useState(0);

  const [wglp, setWglp] = useState(null);
  const [Rect, setRect] = useState(null);

  const [initialX, setInitialX] = useState(0);

  const [pinchZoom, setPinchZoom] = useState(false);
  function getRandomNumber() {
    // 1% chance to pick from the extended range
    if (Math.random() < 0.01) {
      // Randomly decide to pick either a negative or positive extended range
      if (Math.random() < 0.5) {
        // Pick a number between -0.9 and -0.7
        return Math.random() * 0.2 - 0.9;
      } else {
        // Pick a number between 0.7 and 0.9
        return Math.random() * 0.2 + 0.7;
      }
    } else {
      // 99% chance to pick from the standard range
      return Math.random() * 1.4 - 0.7;
    }
  }

  useEffect(() => {
    const canvasPlot = canvasPlotRef.current;
    const canvasX = canvasXAxisRef.current;
    const canvasY = canvasYAxisRef.current;

    const devicePixelRatio = window.devicePixelRatio || 1;

    const initCanvas = (canvas) => {
      canvas.width = canvas.clientWidth * devicePixelRatio;
      canvas.height = canvas.clientHeight * devicePixelRatio;
      const ctx2d = canvas.getContext("2d");
      if (ctx2d) {
        ctx2d.font = "16px Courier New";
        ctx2d.fillStyle = "white";
        ctx2d.strokeStyle = "white";
      }
      return ctx2d;
    };

    const ctxX = initCanvas(canvasX);
    const ctxY = initCanvas(canvasY);

    const updateX = (ctx2d, width, height, scale, offset, divs) => {
      ctx2d.clearRect(0, 0, width, height);
      for (let i = 0; i < divs; i++) {
        const midpoint = -(offset - i / (divs / 2) + 1) / scale;
        const x = (i / divs) * width;
        ctx2d.fillText(`${midpoint.toExponential(2)}`, x, 15);
        ctx2d.moveTo(x, 0);
        ctx2d.lineTo(x, 10);
        ctx2d.stroke();
      }
    };

    const updateY = (ctx2d, width, height, scale, offset, divs) => {
      ctx2d.clearRect(0, 0, width, height);
      for (let i = 0; i < divs; i++) {
        const midpoint = -(offset + i / (divs / 2) - 1) / scale;
        const y = (i / divs) * height;
        ctx2d.fillText(`${midpoint.toExponential(2)}`, 5, y);
        ctx2d.moveTo(width - 10, y);
        ctx2d.lineTo(width, y);
        ctx2d.stroke();
      }
    };

    const updateAxisX = (scale, offset, divs) => {
      if (ctxX) {
        updateX(ctxX, canvasX.width, canvasX.height, scale, offset, divs);
      }
    };

    const updateAxisY = (scale, offset, divs) => {
      if (ctxY) {
        updateY(ctxY, canvasY.width, canvasY.height, scale, offset, divs);
      }
    };

    let numX = 3000000;
    let numLines = 1;
    let wglpLocal = new WebglPlot(canvasPlot);
    wglpLocal.removeAllLines();

    for (let i = 0; i < numLines; i++) {
      const color = new ColorRGBA(
        Math.random(),
        Math.random(),
        Math.random(),
        1
      );
      const line = new WebglLine(color, numX);
      line.lineSpaceX(-1, 2 / numX);
      wglpLocal.addDataLine(line);
    }

    wglpLocal.linesData.forEach((line) => {
      line.setY(0, getRandomNumber());
      for (let i = 1; i < line.numPoints; i++) {
        let y = line.getY(i - 1) + 0.01 * (Math.round(Math.random()) - 0.5);
        if (y > 0.9) y = 0.9;
        if (y < -0.9) y = -0.9;
        line.setY(i, getRandomNumber());
      }
    });

    // Add zoom rectangle
    const RectLocal = new WebglLine(new ColorRGBA(0.9, 0.9, 0.9, 1), 4);
    RectLocal.loop = true;
    RectLocal.xy = new Float32Array([-0.5, -1, -0.5, 1, 0.5, 1, 0.5, -1]);
    RectLocal.visible = false;
    wglpLocal.addLine(RectLocal);

    setRect(RectLocal);

    console.log(wglpLocal);
    setWglp(wglpLocal);

    const resizeHandler = () => {
      canvasPlot.width = canvasPlot.clientWidth * devicePixelRatio;
      canvasPlot.height = canvasPlot.clientHeight * devicePixelRatio;
      // Re-initialize or update the plot as needed
    };
    resizeHandler();

    // window.addEventListener("resize", resizeHandler);

    const newFrame = () => {
      wglpLocal.update();
      updateAxisX(wglpLocal.gScaleX, wglpLocal.gOffsetX, 8);
      updateAxisY(wglpLocal.gScaleY, wglpLocal.gOffsetY, 8);
      requestAnimationFrame(newFrame);
    };

    requestAnimationFrame(newFrame);

    return () => {
      resizeHandler();
      //   window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const dblClick = (e) => {
    e.preventDefault();
    if (wglp) {
      wglp.gScaleX = 1;
      wglp.gOffsetX = 0;
      wglp.gScaleY = 1;
      wglp.gOffsetY = 0;
    }
  };

  const contextMenu = (e) => {
    e.preventDefault();
  };

  const mouseDown = (e) => {
    e.preventDefault();
    const devicePixelRatio = window.devicePixelRatio || 1;
    if (e.button === 0) {
      setZoom(true);
      const canvasPlot = canvasPlotRef.current;
      if (canvasPlot) {
        canvasPlot.style.cursor = "pointer";
        const newCursorDownX =
          (2 * (e.clientX * devicePixelRatio - canvasPlot.width / 2)) /
          canvasPlot.width;
        setCursorDownX(newCursorDownX);
        Rect.visible = true;
      }
    }
    if (e.button === 2) {
      setDrag(true);
      const canvasPlot = canvasPlotRef.current;
      if (canvasPlot) {
        canvasPlot.style.cursor = "grabbing";
        setDragInitialX(e.clientX * devicePixelRatio);
        setDragOffsetOldX(wglp.gOffsetX);
        setDragInitialY(e.clientY * devicePixelRatio);
        setDragOffsetOldY(wglp.gOffsetY);
      }
    }
  };

  const mouseMove = (e) => {
    e.preventDefault();
    const devicePixelRatio = window.devicePixelRatio || 1;
    if (zoom) {
      const canvasPlot = canvasPlotRef.current;
      const cursorOffsetX =
        (2 * (e.clientX * devicePixelRatio - canvasPlot.width / 2)) /
        canvasPlot.width;
      const dw = cursorOffsetX - cursorDownX;
      Rect.xy = new Float32Array([
        cursorDownX,
        -1,
        cursorDownX,
        1,
        cursorOffsetX,
        1,
        cursorOffsetX,
        -1,
      ]);
    }
    if (drag) {
      const canvasPlot = canvasPlotRef.current;
      const dragOffsetX =
        ((e.clientX * devicePixelRatio - dragInitialX) / canvasPlot.width) * 2;
      const dragOffsetY =
        ((e.clientY * devicePixelRatio - dragInitialY) / canvasPlot.height) * 2;
      wglp.gOffsetX = dragOffsetOldX - dragOffsetX;
      wglp.gOffsetY = dragOffsetOldY + dragOffsetY;
    }
  };

  const mouseUp = (e) => {
    e.preventDefault();
    if (zoom) {
      setZoom(false);
      const devicePixelRatio = window.devicePixelRatio || 1;
      const canvasPlot = canvasPlotRef.current;
      canvasPlot.style.cursor = "auto";
      const cursorUpX =
        (2 * (e.clientX * devicePixelRatio - canvasPlot.width / 2)) /
        canvasPlot.width;
      const dX = cursorUpX - cursorDownX;
      wglp.gScaleX = wglp.gScaleX * (2 / Math.abs(dX));
      wglp.gOffsetX =
        wglp.gOffsetX - ((cursorUpX + cursorDownX) / 2) * wglp.gScaleX;
      Rect.visible = false;
    }
    if (drag) {
      setDrag(false);
      const canvasPlot = canvasPlotRef.current;
      canvasPlot.style.cursor = "auto";
    }
  };

  const wheel = (e) => {
    e.preventDefault();
    const wheelUnit = 0.05;
    if (e.ctrlKey) {
      wglp.gScaleY = wglp.gScaleY * (1 + Math.sign(e.deltaY) * wheelUnit);
    } else {
      wglp.gScaleX = wglp.gScaleX * (1 + Math.sign(e.deltaY) * wheelUnit);
    }
  };

  const touchStart = (e) => {
    e.preventDefault();
    const devicePixelRatio = window.devicePixelRatio || 1;
    if (e.touches.length > 1) {
      setPinchZoom(true);
      const dX = e.touches[0].clientX - e.touches[1].clientX;
      const dY = e.touches[0].clientY - e.touches[1].clientY;
      setInitialX(Math.sqrt(dX * dX + dY * dY));
    } else {
      setDrag(true);
      const canvasPlot = canvasPlotRef.current;
      if (canvasPlot) {
        canvasPlot.style.cursor = "grabbing";
        setDragInitialX(e.touches[0].clientX * devicePixelRatio);
        setDragOffsetOldX(wglp.gOffsetX);
        setDragInitialY(e.touches[0].clientY * devicePixelRatio);
        setDragOffsetOldY(wglp.gOffsetY);
      }
    }
  };

  const touchMove = (e) => {
    e.preventDefault();
    const devicePixelRatio = window.devicePixelRatio || 1;
    if (pinchZoom) {
      const dX = e.touches[0].clientX - e.touches[1].clientX;
      const dY = e.touches[0].clientY - e.touches[1].clientY;
      const newX = Math.sqrt(dX * dX + dY * dY);
      const scaleFactor = 1 - (newX - initialX) / initialX;
      wglp.gScaleX = wglp.gScaleX * scaleFactor;
      wglp.gScaleY = wglp.gScaleY * scaleFactor;
      setInitialX(newX);
    } else if (drag) {
      const canvasPlot = canvasPlotRef.current;
      const dragOffsetX =
        ((e.touches[0].clientX * devicePixelRatio - dragInitialX) /
          canvasPlot.width) *
        2;
      const dragOffsetY =
        ((e.touches[0].clientY * devicePixelRatio - dragInitialY) /
          canvasPlot.height) *
        2;
      wglp.gOffsetX = dragOffsetOldX - dragOffsetX;
      wglp.gOffsetY = dragOffsetOldY + dragOffsetY;
    }
  };

  const touchEnd = (e) => {
    e.preventDefault();
    const canvasPlot = canvasPlotRef.current;
    if (drag) {
      setDrag(false);
      canvasPlot.style.cursor = "auto";
    }
    if (pinchZoom) {
      setPinchZoom(false);
    }
  };

  //   const updateTextDisplay = () => {
  //     if (wglp) {
  //       const scaleElementX = document.getElementById("scaleX");
  //       const scaleElementY = document.getElementById("scaleY");
  //       const offsetElementX = document.getElementById("offsetX");
  //       const offsetElementY = document.getElementById("offsetY");

  //       if (scaleElementX) scaleElementX.textContent = wglp.gScaleX.toPrecision(3);
  //       if (scaleElementY) scaleElementY.textContent = wglp.gScaleY.toPrecision(3);
  //       if (offsetElementX) offsetElementX.textContent = wglp.gOffsetX.toPrecision(3);
  //       if (offsetElementY) offsetElementY.textContent = wglp.gOffsetY.toPrecision(3);
  //     }
  //   };

  return (
    <div
      id="plot"
      style={{
        margin: "0 auto",
        display: "grid",
        gridTemplateColumns: "1fr 20fr",
        gridTemplateRows: "20fr 1fr",
        height: "90vh",
        width: "90vw",
      }}
    >
      {/* {console.log(wglpLocal)} */}
      <div
        id="yaxis"
        style={{
          gridColumn: "1",
          gridRow: "1",
          backgroundColor: "black",
          display: "flex",
          justifyContent: "left",
          flexDirection: "column",
        }}
      >
        <canvas
          ref={canvasYAxisRef}
          id="canvas-yaxis"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div
        id="xaxis"
        style={{
          gridColumn: "2",
          gridRow: "2",
          backgroundColor: "black",
        }}
      >
        <canvas
          ref={canvasXAxisRef}
          id="canvas-xaxis"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div
        id="plotarea"
        style={{
          gridColumn: "2",
          gridRow: "1",
          backgroundColor: "black",
          overflow: "hidden",
        }}
      >
        <canvas
          ref={canvasPlotRef}
          id="canvas-webgl"
          style={{
            width: "100%",
            height: "100%",
          }}
          onMouseDown={mouseDown}
          onMouseMove={mouseMove}
          onMouseUp={mouseUp}
          onWheel={wheel}
          onDoubleClick={dblClick}
          onContextMenu={contextMenu}
          onTouchStart={touchStart}
          onTouchMove={touchMove}
          onTouchEnd={touchEnd}
        />
      </div>
    </div>
  );
};

export default Plot3;
