import { Box, Divider, Stack, Typography } from "@mui/material";
// import { ReactComponent as LogoIcon } from "../assets/logo/healthblox_icon.svg";
import { ReactComponent as LogoText } from "../assets/logo/healthblox_text.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Social } from "../components/social";
import { CustomTextButton } from "../components/common/CustomButton";
import { HealthBloxLogo } from "../components/common/LogoText";
export const LoginLayout = ({
  children,
  heading,
  subheading,
  social = true,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useParams();
  return (
    <Box
      width={"100%"}
      sx={{
        display: { xs: "flex", lg: "grid" },
        flexDirection: "column",
        gridTemplateColumns: "1fr 1fr",
        minHeight: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          background: "#B5CFC6",
          color: "var(--mui-palette-common-white)",
          display: { xs: "none", lg: "flex" },
          justifyContent: "center",
          p: 3,
        }}
      >
        <Stack spacing={3}>
          <Stack spacing={1}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                color="inherit"
                sx={{
                  fontSize: "24px",
                  lineHeight: "32px",
                  textAlign: "center",
                }}
                variant="h1"
              >
                Welcome to{" "}
                {/* <span style={{ fontFamily: "Avenir-light", fontWeight: "bold" }}>
                HEALTHBLOX
              </span> */}
              </Typography>
              <LogoText style={{ height: "40px" }} />
            </Stack>
            <Typography align="center" variant="subtitle1">
              Your building Blox for health
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box
        m={2}
        p={3}
        sx={{
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          scrollbarWidth: "none",
          // justifyContent: "center",
          overflowY: "auto",
          alignItems: " center",
        }}
      >
        <Box maxWidth={400} width={"100%"}>
          <Stack spacing={4}>
            <HealthBloxLogo height={"30px"} />
            {/* <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <LogoIcon
                style={{
                  height: "30px",
                  margin: 0,
                  display: "block",
                }}
              />
              <LogoText style={{ height: "30px" }} />
            </Box> */}
            <Stack spacing={1}>
              <Typography variant="h5" sx={{ color: "#212636" }}>
                {heading}
              </Typography>

              {(heading === "Sign up" || heading === "Sign in") && (
                <Stack display={"flex"} flexDirection={"row"}>
                  <Typography variant="body2" sx={{ color: "#667085" }}>
                    {heading === "Sign in" ? "Don't" : "Already"} have an
                    account?{"  "}
                  </Typography>
                  <CustomTextButton
                    onClick={() => {
                      console.log(process.env.REACT_APP_ENVIRONMENT);
                      heading === "Sign in"
                        ? process.env.REACT_APP_ENVIRONMENT !== "prod"
                          ? navigate("/signup")
                          : window.location.replace(
                              "https://www.healthblox.care/contact-us"
                            )
                        : navigate(
                            // heading === "Sign in"
                            //   ? "/signup"
                            //   :
                            token ? `/join-organization/${token}` : "/login"
                          );
                    }}
                  >
                    {heading === "Sign in" ? "Sign up" : "Sign in"}
                  </CustomTextButton>
                </Stack>
              )}
              {subheading && (
                <Typography variant="body1" sx={{ color: "#212636" }}>
                  {subheading}
                </Typography>
              )}
            </Stack>
            {social && process.env.REACT_APP_ENVIRONMENT !== "prod" && (
              <>
                <Social redirect={location.state?.from.pathname} />
                <Stack>
                  <Divider>or</Divider>
                </Stack>
              </>
            )}
            {children}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
