import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import "./widget.css";
import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { getAuthToken } from "../../../utils/cookie";
import axios from "axios";
import { Skeleton } from "@mui/material";

const Widget = ({ widget }) => {
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Widget",
    item: { widget, type: "widget"},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  async function checkForPaymentStatus() {
    const API_URL = `${process.env.REACT_APP_PAYMENT_SERVER}/orgs/status`;
    const headers = getAuthToken();

    try {
      const response = await axios.get(API_URL, headers);
      const status = response?.data?.status;
      if (status === "enabled"){
        checkForConnectID()
        setPaymentEnabled(true);
      } 
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  

  async function checkForConnectID() {
    const API_URL = `${process.env.REACT_APP_PAYMENT_SERVER}/orgs/connect-id`;
    const headers = getAuthToken();

    try {
      const response = await axios.get(API_URL, headers);
      const connectID = response?.data?.connect_id;
      widget.connectID = connectID
    } catch (error) {
      console.error(error);
    }
  }
  

  useEffect(() => {
    checkForPaymentStatus();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton
            variant="rectangular"
            width={250}
            height={150}
            className="rounded"
          />
        </>
      ) : (
        <>
          {paymentEnabled ? (
            <div
              ref={drag}
              style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: "move",
              }}
              className="card w-64 h-32 custom-card my-1 p-2 cursor-pointer"
            >
              <div className="text-center font-semibold">
                {widget.label}
              </div>
            </div>
          ) : (
            <div className="card w-64 h-32 custom-card my-1 p-2 cursor-pointer">
              <div className="text-center font-semibold pb-2">
                Payment is not active, Please click the below link to enable
                Payment
              </div>
              <Link
                className="px-3 py-1 shadow rounded mustard_bg no-underline my-3 text-lg font-semibold text-white flex justify-center"
                to={"/organization_settings/payments"}
              >
                Enable Payment
                <IoIosArrowRoundForward
                  size={28}
                  className="items-center font-extrabold"
                />
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Widget;
