import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import "./userForms.css";
import { useNavigate } from "react-router-dom";
import { MdModeEdit } from "react-icons/md";
import { getAllForms, deleteForm, updateFormStatus } from "../../utils/form";
import Container from "@mui/material/Container";
import TableLayout from "../../components/common/TableLayout";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiDraftFill } from "react-icons/ri";
import { HiClock } from "react-icons/hi2";
import { FiMinusCircle } from "react-icons/fi";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import PageHeading from "../../components/common/PageHeading";
import { Add } from "@mui/icons-material";

const UserForms = () => {
  const [formList, setFormList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openAllStatus, setOpenAllStatus] = useState(null);

  let navigate = useNavigate();

  const getAllFormsData = async () => {
    setIsLoading(true);
    const response = await getAllForms();
    setFormList(response?.data.forms);
    setIsLoading(false);
  };
  const handleDeleteForm = async (id) => {
    setIsLoading(true);
    await deleteForm(id);
    setFormList(formList?.filter((form) => form._id !== id));
    setIsLoading(false);
  };

  const handleStatusUpdate = async (status, id) => {
    setIsLoading(true);
    await updateFormStatus(id, status);
    await getAllFormsData();
    setOpenAllStatus(null);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllFormsData();
  }, []);

  const columns = [
    { id: "label", label: "Form Name", align: "center" },
    {
      id: "creator_name",
      label: "Creator Name",
      minWidth: 100,
      align: "center",
    },
    {
      id: "created_at",
      label: "Created at",
      align: "center",
      format: (value) => value?.split("T")[0].split("-").reverse().join("-"),
    },
    { id: "org_name", label: "Organisation", align: "center" },
    {
      id: "status",
      label: "Status",
      align: "center",
      format: (value, row) => statusFormatter(value, row),
    },
    {
      id: "actions",
      label: "Actions",
      align: "center",
      format: (value, row) => actionFormatter(value, row),
    },
  ];

  const filters = [
    { label: "All", id: "ALL", filterKey: "status", ICON: "" },
    { label: "New", id: "NEW", filterKey: "status", ICON: "MdFiberNew" },
    {
      label: "Published",
      id: "PUBLISHED",
      filterKey: "status",
      ICON: "IoIosCheckmarkCircle",
    },
    { label: "Draft", id: "DRAFT", filterKey: "status", ICON: "RiDraftFill" },
    { label: "Archived", id: "ARCHIVED", filterKey: "status", ICON: "HiClock" },
  ];

  const statusFormatter = (status, row) => {
    const statusConfig = {
      NEW: {
        icon: <HiClock className="my-auto" color={"gray"} />,
        text: "New",
      },
      ARCHIVED: {
        icon: <FiMinusCircle className="my-auto" color={"red"} />,
        text: "Archived",
      },
      DRAFT: {
        icon: <RiDraftFill className="my-auto" color={"red"} />,
        text: "Draft",
      },
      PUBLISHED: {
        icon: <IoIosCheckmarkCircle className="my-auto" color={"green"} />,
        text: "Published",
      },
    };
    const config = statusConfig[status];
    if (config) {
      return (
        <>
          <div className="flex justify-center">
            <div className="rounded-2xl border-gray-700 border flex w-fit px-2">
              {config.icon}
              <span className="pl-1">{config.text}</span>
            </div>
            <div className="flex px-1 w-fit px-2 my-auto">
              <MdModeEdit
                className="cursor-pointer"
                onClick={() => setOpenAllStatus(row?._id)}
              />
            </div>
          </div>
          {openAllStatus && openAllStatus === row?._id ? (
            <>
              <div className="flex justify-center pt-2 gap-2">
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("NEW", row._id)}
                >
                  New
                </div>
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("ARCHIVED", row._id)}
                >
                  Archived
                </div>
              </div>
              <div className="flex justify-center pt-1 gap-2">
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("DRAFT", row._id)}
                >
                  Draft
                </div>
                <div
                  className="rounded-2xl border-gray-700 border w-fit px-2 cursor-pointer filter-button hover:text-white"
                  onClick={() => handleStatusUpdate("PUBLISHED", row._id)}
                >
                  Published
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <div className="rounded-2xl border-gray-700 border flex px-1 w-fit">
          <span>{status}</span>
        </div>
      );
    }
  };

  const actionFormatter = (value, row) => {
    return (
      <div className="flex justify-center">
        <MdDelete
          size={32}
          className="cursor-pointer p-1"
          onClick={() => handleDeleteForm(row?._id)}
        />
        <Link
          key={row?.label}
          className="no-underline px-2 self-center	"
          to={`/edit-form/${row?._id}`}
        >
          <MdModeEdit size={20} className="cursor-pointer purple-text" />
        </Link>
      </div>
    );
  };

  return (
    <Box m={2} pb={4}>
      <PageHeading
        heading={"User forms"}
        icon={<Add />}
        iconText={"Create New Form"}
        iconCLick={() => navigate("/edit-form")}
      />
      {isLoading ? (
        <div className="flex justify-center py-24">
          <CircularProgress className="m-auto purple-text" />
        </div>
      ) : (
        <TableLayout columns={columns} rows={formList} filters={filters} />
      )}
    </Box>
  );
};
export default UserForms;
