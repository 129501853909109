import { useState, useEffect } from "react";
import "./common.scss";

const CheckBoxSelector = ({ data, setAnswer, formValidation }) => {
  const [selectedList, setSelectedList] = useState([]);
  const [validForm, setFormValidation] = useState(formValidation);

  const handleCheckboxClick = (value) => {
    if (selectedList?.includes(value)) {
      let optionsData = [...selectedList];
      optionsData.splice(optionsData.indexOf(value), 1);
      setSelectedList(optionsData);
      setAnswer(data, { value: optionsData });
      setFormValidation(true);
      // setAnswer({ question: data.label, answer: optionsData });
    } else {
      setSelectedList([...selectedList, value]);
      setAnswer(data, { value: [...selectedList, value] });
      setFormValidation(true);
      // setAnswer({ question: data.label, answer: [...selectedList, value] });
    }
  };

  useEffect(() => {
    setFormValidation(formValidation);
    if (data?.answer) {
      setSelectedList(data.answer?.value);
    }
  }, [formValidation]);
  return (
    <div className="">
      <div className="font-semibold text-xl pt-3">{data.label}</div>
      {data?.validate?.required ? (
        <span className={!validForm ? "text-red-700" : ""}> * </span>
      ) : (
        <></>
      )}
      {data?.validate?.required && !validForm ? (
        <span className="text-red-700 font-light text-xs">
          {" "}
          Required Field{" "}
        </span>
      ) : (
        <></>
      )}
      {data.values && data.values.length ? (
        <>
          <form>
            {data.values.map((item, index) => {
              return (
                <div
                  className="radio-common my-3"
                  // onClick={() => handleCheckboxClick(item.value)}
                >
                  <label>
                    <input
                      type="checkbox"
                      key={index}
                      value={item.value}
                      checked={selectedList.includes(item.value)}
                      className="mr-2"
                      onChange={() => handleCheckboxClick(item.value)}
                    />
                    {item.label}
                  </label>
                </div>
              );
            })}
          </form>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CheckBoxSelector;
