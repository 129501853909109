import React, { useEffect, useState, useContext } from "react";
import ProgressTracker from "../../components/progressTracker";
import { useNavigate, useParams } from "react-router-dom";
import {
  getJourneyDataForUsers,
  getFormDataForUsers,
  getStandardFormData,
} from "../../utils/form";
import ScreenRenderer from "./screenRenderer";
import FormRendererContext from "../../services/context/FormRendererContext";
import FormRenderer from "./formRenderer";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TfiCheckBox } from "react-icons/tfi";
import StripeCheckout from "../PaymentElement/stripePayment";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  // bgcolor: 'background.paper hjdsgbfuhds kjdhiudh',
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const JourneyRenderer = () => {
  const { journeyId } = useParams();

  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveSteps] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [journeySchema, setJourneySchema] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const {
    journeyData,
    setJourneyData,
    activeScreenID,
    setActiveScreenID,
    activeScreenPageID,
    setActiveScreenPageID,
    activeComponent,
    setActiveComponent,
    activeFormPage,
    setActiveFormPage,
  } = useContext(FormRendererContext);

  let navigate = useNavigate();

  function findSteps(schema) {
    let stepsArray = [];
    for (let i = 0; i < schema?.screens?.length; i++) {
      if (schema.screens[i].label) stepsArray.push(schema.screens[i].label);
    }
    return stepsArray;
  }

  function handleNext() {
    let screens = journeyData?.journey_schema?.screens;
    for (let i = 0; i < screens?.length; i++) {
      if (screens[i]?.id === activeScreenID) {
        let screenPages = screens[i]?.screenPages;
        for (let j = 0; j < screenPages?.length; j++) {
          if (screenPages[j]?.id === activeScreenPageID) {
            if (j + 1 === screenPages?.length) {
              if (i + 1 !== screens?.length) {
                setActiveSteps(i + 1);
                setActiveScreenID(screens[i + 1]?.id);
                setActiveScreenPageID(screens[i + 1]?.screenPages[0]?.id);
                setActiveComponent(screens[i + 1]?.screenPages[0]?.components);
                setActiveFormPage(
                  screens[i + 1]?.screenPages[0]?.components?.form?.form_schema
                    ?.formData[0]
                );
              } else {
                submitFinalJourney();
              }
            } else {
              setActiveScreenPageID(screenPages[j + 1]?.id);
              setActiveComponent(screenPages[j + 1]?.components);
              setActiveFormPage(
                screenPages[j + 1]?.components?.form?.form_schema?.formData[0]
              );
            }
          }
        }
      }
    }
  }

  function handleBack() {
    let screens = journeyData?.journey_schema?.screens;
    for (let i = 0; i < screens?.length; i++) {
      if (screens[i]?.id === activeScreenID) {
        let screenPages = screens[i]?.screenPages;
        for (let j = 0; j < screenPages?.length; j++) {
          if (screenPages[j]?.id === activeScreenPageID) {
            if (j === 0) {
              if (i > 0) {
                setActiveSteps(i - 1);
                setActiveScreenID(screens[i - 1]?.id);
                setActiveScreenPageID(
                  screens[i - 1]?.screenPages[
                    screens[i - 1]?.screenPages?.length - 1
                  ]?.id
                );
                setActiveComponent(
                  screens[i - 1]?.screenPages[
                    screens[i - 1]?.screenPages?.length - 1
                  ]?.components
                );
                let formPage =
                  screens[i - 1]?.screenPages[
                    screens[i - 1]?.screenPages?.length - 1
                  ]?.components?.form?.form_schema?.formData;
                setActiveFormPage(
                  screens[i - 1]?.screenPages[
                    screens[i - 1]?.screenPages?.length - 1
                  ]?.components?.form?.form_schema?.formData[
                    formPage?.length - 1
                  ]
                );
              }
            } else {
              setActiveScreenPageID(screenPages[j - 1]?.id);
              setActiveComponent(screenPages[j - 1]?.components);
              let formPage =
                screenPages[j - 1]?.components?.form?.form_schema?.formData;
              setActiveFormPage(
                screenPages[j - 1]?.components?.form?.form_schema?.formData[
                  formPage?.length - 1
                ]
              );
            }
          }
        }
      }
    }
  }
  const setAnswerForWidget = () => {
    let updatedJourneyData = JSON.parse(JSON.stringify(journeyData));
    let screens = updatedJourneyData?.journey_schema?.screens;

    for (let i = 0; i < screens?.length; i++) {
      if (screens[i]?.id === activeScreenID) {
        let screenPages = screens[i]?.screenPages;
        for (let j = 0; j < screenPages?.length; j++) {
          if (
            screenPages[j]?.id === activeScreenPageID &&
            screenPages[j].components?.type === "widget"
          ) {
            screenPages[j].components.answer = {
              status: "payment-successful",
              message: "You Payment is confirmed",
            };
          }
        }
      }
    }
    setJourneyData(updatedJourneyData);
  };

  const submitFinalJourney = () => {
    console.log("FINAL JOURNEY SUBMISSION", journeyData);
    setOpenConfirmationModal(true);
  };

  const fetchFormSchema = async (formData) => {
    let res;
    if (formData?.type === "user-form")
      res = await getFormDataForUsers(formData?.formId);
    if (formData?.type === "standard-form")
      res = await getStandardFormData(formData?.formId);
    // change schema to from_schema for standard form from backend and also in form playground where getStandardFormData is called.
    // clean button schemas
    let form = res?.data?.form;
    form?.form_schema?.formData?.map((formPage) => {
      // formPage?.contents && formPage?.content?.components && formPage?.content?.components.map((item)=>{
      formPage.content.components = formPage?.content?.components.filter(
        (item) =>
          !(
            item?.key === "submit" &&
            item?.type === "button" &&
            item?.action === "submit"
          )
      );
    });
    form.form_schema.formData = form?.form_schema?.formData.filter(
      (formPage) => formPage?.content?.components?.length !== 0
    );

    return form;
  };

  const updateJourneySchema = async (id) => {
    const response = await getJourneyDataForUsers(id, () => navigate("/form-journey"));
    if(response?.data?.journey) {
    let journeyData = JSON.parse(JSON.stringify(response?.data?.journey));
    let schema = await updateSchemaWithFormData(journeyData?.journey_schema);

    setJourneyData(journeyData);
    setJourneySchema(schema);
    setSteps(findSteps(schema));
    setActiveScreenID(schema?.screens[0]?.id);
    setActiveScreenPageID(schema?.screens[0]?.screenPages[0]?.id);
    setActiveComponent(schema?.screens[0]?.screenPages[0]?.components);
    setActiveFormPage(
      schema?.screens[0]?.screenPages[0]?.components?.form?.form_schema
        ?.formData[0]
    );
    setIsLoading(false);
    }
  };

  const updateSchemaWithFormData = async (schema) => {
    const updateScreenPage = async (screenPage) => {
      if (
        screenPage &&
        screenPage.components &&
        (screenPage.components.type === "user-form" ||
          screenPage.components.type === "standard-form")
      ) {
        let form = await fetchFormSchema(screenPage.components);
        screenPage.components.form = form;
      }
    };

    const updateScreen = async (screen) => {
      await Promise.all(screen.screenPages.map(updateScreenPage));
    };

    await Promise.all(schema?.screens?.map(updateScreen));

    return schema;
  };

  useEffect(() => {
    if (journeyId) {
      updateJourneySchema(journeyId);
    }
  }, []);

  if (!journeyId) return <></>;
  return (
    <div className="m-auto">
      {!isLoading ? (
        <>
          <div className="text-3xl text-center font-bold py-5">
            User Journey Form
          </div>
          <div className="w-fit m-auto">
            <ProgressTracker steps={steps} currentStep={activeStep} />
          </div>
          <div>
            {/* {activeScreen ? (
          <ScreenRenderer
            screenData={activeScreen}
            handleNextScreen={handleNext}
            handleBackScreen={handleBack}
          />
        ) : (
          <></>
        )} */}
            {activeComponent &&
            activeComponent?.form &&
            (activeComponent?.type === "user-form" ||
              activeComponent?.type === "standard-form") ? (
              <FormRenderer
                formData={activeComponent}
                handleNextScreenPage={handleNext}
                handleBackScreenPage={handleBack}
                activeFormPageData={activeFormPage}
              />
            ) : activeComponent && activeComponent?.type === "widget" ? (
              <div className="flex justify-center">
                {activeComponent?.answer &&
                activeComponent?.answer?.status === "payment-successful" ? (
                  <div className="flex flex-col">
                    <div className="text-center font-semibold text-lg py-3 shadow my-5 px-24 rounded bg-gray-100">
                      {activeComponent?.answer?.message}
                    </div>
                    <div className="flex justify-between pt-3 pb-36">
                      <button
                        onClick={handleBack}
                        className="flex text-xl rounded shadow p-2 mustard_bg text-white px-3 cursor-pointer hover:bg-indigo-400"
                      >
                        <FiChevronsLeft size={28} /> Back
                      </button>
                      <button
                        onClick={handleNext}
                        className="flex text-xl rounded shadow p-2 mustard_bg text-white px-3 cursor-pointer hover:bg-indigo-400"
                      >
                        Next
                        <FiChevronsRight size={28} />
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <StripeCheckout
                      org_id={activeComponent?.widget?.org_id}
                      amount={activeComponent?.widget?.amount || 0}
                      handleNextScreenPage={handleNext}
                      handleBackScreenPage={handleBack}
                      setAnswerForWidget={setAnswerForWidget}
                    />
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      {openConfirmationModal ? (
        <>
          <Modal
            open={openConfirmationModal}
            onClose={() => {}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="rounded bg-zinc-300">
              <div className="text-center">
                <TfiCheckBox
                  size={52}
                  className="my-auto ml-1 p-1 inline-block"
                />
              </div>
              <div className="font-semibold text-2xl py-1 text-center">
                Thank You !
              </div>
              <div className="text-xl py-1 text-center">
                Your response has been submitted successfully
              </div>
            </Box>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default JourneyRenderer;
